const state = {
  info: null,
};

const getters = {
  getAppInfo: (state) => {
    return state.info;
  },
};

const mutations = {
  setInfo(state, info) {
    state.info = info;
  },
}

const actions = {
  setAppInfo({ commit }, info) {
    commit("setInfo", info);
  },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
