const state = {
	token: JSON.parse(localStorage.getItem("external_auth_token")) || null
};

const mutations = {
	setExternalAuth(state, token) {
		state.token = token;
	},
	clearExternalAuth(state) {
		state.token = {};
	}
}

const actions = {
	setExternalAuth({ commit }, token) {
		commit("setExternalAuth", token);
		localStorage.setItem("external_auth_token", JSON.stringify(token));
	},
	clearExternalAuth({ commit }) {
		commit("clearExternalAuth");
		localStorage.removeItem("external_auth_token");
	}
}

const getters = {
	getExternalAuthToken: state => state.token,
	isProvisioningAuth: state => state.token?.uc_type?.toUpperCase() === 'PROV',
	isAuditAuth: state => state.token?.uc_type?.toUpperCase() === 'AUDIT'
}

export default {
	state,
	mutations,
	actions,
	getters
}