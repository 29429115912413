<template>
  <div>
    <div class="row pb-3">
      <div class="col">
        <b-dropdown text="Select vendor..." class="customer-dropdown w-auto pr-2" id="dropSelectCloudVendor">
          <template #button-content>
            <span v-if="selectedPbx">
              <img :src="getImgUrl(selectedPbx.icon)" /> {{ selectedPbx.desc }}
            </span>
            <span v-else>Select a vendor...</span>
          </template>

          <b-dropdown-item v-for="pabx in getPABXTypes.filter((pbx) =>
            isDirectPbxSupported(pbx)
          )" :key="pabx.type + pabx.icon" @click="setSelectedPbx(pabx)">
            <img :src="getImgUrl(pabx.icon)" /> {{ pabx.desc }}
          </b-dropdown-item>
        </b-dropdown>

        <base-button v-if="isCloudAuth" id="btnAuthenticate" type="primary" @click="signin">
          Authenticate
        </base-button>
      </div>
    </div>

    <div v-if="hasFields">
      <div class="row" v-for="field in selectedPbx.fields" :key="field.fieldName">
        <div class="col">
          <h4>{{ field.desc }}</h4>
          <base-input :id="`input-${field.fieldName}`" :type="field.type" v-if="field.type == 'text' ||
            field.type == 'url' ||
            field.type == 'password' ||
            field.type == 'email' ||
            field.type == 'number'
            " v-model="field.value" :placeholder="field.placeholder" />
        </div>
      </div>
      <button class="btn btn-primary float-right" @click="executeDirectAudit">
        Audit
      </button>
    </div>
  </div>
</template>

<script>
import { getImgUrl } from "@/utils/images";
import { mapGetters, mapActions } from "vuex";
import { signIn } from "@/services/externalAuthManager";

export default {
  data() {
    return {
      selectedPbx: null,
      getImgUrl,
    };
  },
  computed: {
    ...mapGetters(["getPABXTypes"]),

    isCloudAuth() {
      return this.selectedPbx?.settings;
    },

    hasFields() {
      return this.selectedPbx?.fields;
    },
  },
  methods: {
    ...mapActions(["clearExternalAuth"]),

    setSelectedPbx(pbx) {
      this.selectedPbx = pbx;
    },

    executeDirectAudit() {
      this.$emit("fire-direct-audit", this.selectedPbx);
    },

    isDirectPbxSupported(pbx) {
      return pbx && (pbx.fields || pbx.settings);
    },

    async signin() {
      this.clearExternalAuth();

      signIn('Audit', this.selectedPbx.databaseType, this.selectedPbx.settings);
    }
  },
};
</script>
