import axios from 'axios';

const UCentricLiveURL = 'https://live.ucentric.cloud/auth';
const StateId = "UC_ZT_Status"

const settings = {
  redirect_uri: UCentricLiveURL,
  authority: '', // Dynamically set
  client_id: '', // Dynamically set
  client_secret: '' // Dynamically set
}

/*
    Returns an OAuth settings object
*/
function getOAuthSettings(type, identifier, clientSettings) {
  let authSettings = settings;
  authSettings.extraTokenParams = { type: type, identifier: identifier };
  authSettings.client_id = clientSettings.clientId
  authSettings.authority = clientSettings.url

  if (clientSettings.clientSecret) {
    authSettings.client_secret = clientSettings.clientSecret
  }

  if (clientSettings.scopes) {
    authSettings.scope = clientSettings.scopes.join(' ');
  }

  return authSettings;
}

/*
  Attempt to get the OIDC confugration from the provider. This will only work if they support OIDC.
*/
async function getOidcConfiguration(url) {
  try {
    const oidcConfigurationResponse = await axios.get(`${url}/.well-known/openid-configuration`)
    return oidcConfigurationResponse?.data;
  } catch (error) {
    return null;
  }
}

/*
  If the provider supports the user-profile endpoint then add the supported scopes to let us collect the information.
*/
function appendUserProfileScopes(oidcConfiguration, settings) {
  if (!oidcConfiguration?.scopes_supported) {
    return;
  }
  if (settings.scopes) {
    settings.scopes = settings.scopes.concat(oidcConfiguration.scopes_supported);
  } else {
    settings.scopes = oidcConfiguration.scopes_supported;
  }
}

/*
  Reads endpoints from the OIDC configuration otherwise it will fallback to the settings within the UCentric config
    authorizationEndpoint
    tokenEndPoint
    userInfoEndpoint
    proxyAuth
*/
function getOidcEndPointConfiguration(oidcConfiguration, settings) {
  return {
    authorization_endpoint: oidcConfiguration?.authorization_endpoint ?? settings?.authorizationEndpoint,
    token_endpoint: oidcConfiguration?.token_endpoint ?? settings?.tokenEndPoint,
    userinfo_endpoint: oidcConfiguration?.userinfo_endpoint ?? settings?.userInfoEndpoint,
    proxyAuth: settings?.proxyAuth
  }
}

async function signIn(type, identifier, clientSettings) {
  // Setup settings using OIDC data if it exists, otherwise use UCentric settings.
  const oidcConfiguration = await getOidcConfiguration(clientSettings.url);
  const oauthEndpoints = getOidcEndPointConfiguration(oidcConfiguration, clientSettings);
  appendUserProfileScopes(oidcConfiguration, clientSettings);

  let settings = getOAuthSettings(type, identifier, clientSettings);
  settings.oauthEndpoints = oauthEndpoints;

  localStorage.setItem(StateId, JSON.stringify(settings));

  let authUrl = `${oauthEndpoints.authorization_endpoint}?client_id=${settings.client_id}&response_type=code&state=${StateId}&redirect_uri=${encodeURI(settings.redirect_uri)}`;

  if (settings.scope) {
    authUrl += `&scope=${encodeURIComponent(settings.scope)}`;
  }

  if (settings.client_secret) {
    authUrl += `&client_secret=${settings.client_secret}`;
  }

  window.location.href = `${UCentricLiveURL}?redirecturi=${encodeURIComponent(window.location.href + 'auth-callback')}&authurl=${encodeURIComponent(authUrl)}`;
}

export { signIn };