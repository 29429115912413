<template>
    <b-table striped hover show-empty :fields="fields" :items="files" emptyText="There are no additional files to show.">
        <template #empty="scope">
            <h4>{{ scope.emptyText }}</h4>
        </template>

        <template v-slot:cell(action)="row">
			<button id="btnRemoveFile" title="Remove file" class="btn btn-danger btn-sm" @click="removeFile(row)">
          		✖
        	</button>
      	</template>
    </b-table>
</template>

<script>
export default {
    props: {
        files: {
            type: Array
        }
    },

    data() {
        return {
            fields: [
                {
                    key: "fileName",
                    label: "File Name"
                },
                {
                    key: "fileLabel",
                    label: "File Type"
                },
                {
                    label: "Action",
                    key: "action"
                }
            ]
        }
    },

    methods: {
        // Emit an event containing the new file object we want to remove
        removeFile(row) {
            this.$emit("removeFile", row.item);
        }
    }
}
</script>