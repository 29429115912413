import apiClient from "./baseService";

export default {
	listMessages(scheduleId, siteId, timestamp, asCSV) {
		let queryString = `/ucGetOrchestrationStatus?`;
		let responseType = 'json';

		if (scheduleId) {
			queryString += `&scheduleId=${scheduleId}`;
		}

		if (siteId) {
			queryString += `&amSiteId=${siteId}`;
		}

		if (timestamp) {
			queryString += `&sdate=${timestamp}`;
		}

		if (asCSV) {
			queryString += '&simpleCSV=1';
			responseType = 'blob';
		}
		
		return apiClient.get(queryString, { responseType });
	}
}