import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from '@/views/Dashboard'
import ManageSites from '@/views/ManageSites';
import Jobs from '@/views/Jobs';
import AuthCallback from "@/views/AuthCallback";

import store from "@/store";

Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      component: Dashboard
    },
    {
      path: '/manage-sites',
      component: ManageSites,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/jobs',
      component: Jobs
    },
    {
      path: '/auth-callback',
      component: AuthCallback
    }
  ]
})

// Check if user is logged in before visiting certain routes
router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.authRequired)) {
    if (!store.getters.isLoggedIn || !store.getters.isSPAAdmin) {
      next({ path: "/" });
    }

    // Continue as normal
    else {
      next();
    }
  } else {
    next();
  }
});

export default router;