<template>
  <div class="container-fluid d-flex flex-column p-4 bg-gradient-light align-items-center">
    <img v-if="hasBackground" style="position: absolute;" :src="getBrandedImageFromSubDomain('backgrounds')" width="100%"
      height="95%" />
    <!-- Login and ToS -->
    <Login v-if="!isLoggedIn && !getSSO.Type && !loading && !offline" />
    <SSO v-if="getSSO.Type && !loading && !offline" />
    <TermsOfUse v-if="isLoggedIn && !getAcceptedTerms && !offline && !!getSSO.Terms && !loading" />

    <!-- Show if offline -->
    <b-modal v-model="offline" hide-backdrop centered hide-header-close hide-footer no-close-on-esc no-close-on-backdrop
      cancel-title>
      <template slot="modal-title">
        <h2 class="modal-title">Error!</h2>
      </template>

      <p>Unable to reach server. Please try again or contact your administrator.</p>
    </b-modal>

    <!-- Logo showcase -->
    <div class="pb-4">
      <img ref="logoImage" width="300px" :src="getBrandedImageFromSubDomain()"
        :onerror="`this.src='${getImgUrl(getGroupProfile && getGroupProfile.SPALogo ? getGroupProfile.SPALogo : 'ucentric')}'`"
        alt="logo">
    </div>

    <!-- Tabs -->
    <div class="col-xl-6 pb-4" :class="{ 'disabled': !isLoggedIn, 'background': hasBackground }">
      <tabs ref="ucTabs" tabNavClasses="nav-fill flex-column flex-sm-row">
        <Auditing v-if="!auditingDisabled" />
        <Provisioning v-if="!provisioningDisabled" />
      </tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BOverlay } from 'bootstrap-vue'

import axios from 'axios';
import authService from "@/api/authService";
import appService from "@/api/appService.js";
import { getImgUrl } from "@/utils/images";

// Custom components
import Login from "@/components/Login.vue";
import SSO from "@/components/SSO.vue";
import TermsOfUse from "@/components/TermsOfUse.vue";
import Auditing from "@/components/Auditing.vue";
import Provisioning from "@/components/Provisioning.vue";

import { mapActions, mapGetters } from 'vuex';

Vue.component('b-overlay', BOverlay)

export default {
  components: {
    Login,
    SSO,
    TermsOfUse,
    Auditing,
    Provisioning
  },
  
  data() {
    return {
      getImgUrl,
      offline: false,
      loading: true,
      hasBackground: false
    };
  },

  computed: {
    ...mapGetters([
      "isLoggedIn",
      "getToken",
      "getSSO",
      "getAcceptedTerms",
      "getGroupProfile",
      "provisioningDisabled",
      "auditingDisabled",
      "isProvisioningAuth",
    ])
  },

  methods: {
    ...mapActions([
      "setSSO",
      "setAppInfo"
    ]),

    async checkForBackgroundImage() {
      this.hasBackground = false;
      const backgroundUrl = this.getBrandedImageFromSubDomain('backgrounds');
      if (backgroundUrl) {
        try {
          var response = await axios.get(backgroundUrl);
          if (response?.headers['content-type']?.toString().startsWith('image/') && response?.headers['content-length'] > 0) {
            this.$emit('hide-footer');
            this.hasBackground = true;
          }
        } catch (error) {
          this.hasBackground = false;
        }
      }
    },

    getBrandedImageFromSubDomain(path = 'logos') {
      try {
        let domain = document.location.hostname;
        if (domain.includes("://")) {
          domain = domain.split('://')[1];
        }

        return domain.length > 1 ? `${process.env.BASE_URL}${path}/${domain.split('.')[0]}.png` : null;
      } catch (error) {
        return null;
      }
    }
  },

  async mounted() {
    await this.checkForBackgroundImage();

    // Auto activate provisoning tab if provisioning auth is set
    if (this.isProvisioningAuth) {
      setTimeout(() => {
        this.$refs.ucTabs.findAndActivateTab('Provisioning');
      })
    }

    document.addEventListener("offline", event => {
      if (event) {
        this.offline = true;
      }
    })

    // Fetch SSO details
    try {
      const res = await authService.ssoDetails();
      this.setSSO(res.data);
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }

    // Fetch app variables
    try {
      const res = await appService.getAppVariables();
      this.setAppInfo(res.data);
    }
    catch (e) {
      console.log("An error occurred fetching app variables.");
    }
  },

  watch: {
    provisioningDisabled: function (value) {
      // Auto activate provisoning tab if provisioning auth is set
      var tabLabel = !value && this.auditingDisabled ? 'Provisioning' : 'Auditing';
      setTimeout(() => {
        this.$refs.ucTabs.findAndActivateTab(tabLabel);
      });
    },
    auditingDisabled: function (value) {
      if (!value) {
        setTimeout(() => {
          this.$refs.ucTabs.findAndActivateTab('Auditing');
        });
      }
    },
    isLoggedIn: function () {
      this.$refs.logoImage.error = `this.onerror=null;this.src='${this.getImgUrl(this.getGroupProfile && this.getGroupProfile.SPALogo ? this.getGroupProfile.SPALogo : 'ucentric')}'`;
      this.$refs.logoImage.src = this.getBrandedImageFromSubDomain();
    }
  }
};
</script>

<style scoped>
.background {
  padding-top: 5rem;
}

.disabled {
  pointer-events: none;
}
</style>