<template>
  <b-modal
    hide-backdrop
    centered
    v-model="showModal"
    @close="emitCloseEvent"
    @hidden="emitCloseEvent"
  >
    <template slot="modal-title">
      <h1>Add additional file</h1>
    </template>

    <div class="alert alert-secondary">
      <p>
        Here, you can pick the file type and also upload the file you wish to be
        merged into an Audit.
      </p>
    </div>

    <!-- Error -->
    <div v-if="error.show" class="alert alert-danger">
      <p>{{ error.message }}</p>
    </div>

    <!-- Pick file type -->
    <base-dropdown class="w-100">
      <base-button
        slot="title"
        id="dropPlatformType"
        type="primary"
        class="dropdown-toggle w-100"
        menu-class="w-100"
      >
        {{ usedAllFileTypes ? "No file types available" : selectedFileType.name }}
      </base-button>

      <!-- Get @click event working -->
      <li
        v-for="file in fileTypes"
        :key="file.type"
        id="dropItemFileType"
        @click="changeSelectedFileType(file)"
      >
        <a class="dropdown-item" :class="{ 'disabled': file.disabled }">
          {{ file.name }}
        </a>
      </li>
    </base-dropdown>

    <hr />

    <!-- Additional file upload -->
    <Vue-Dropzone
      id="additionalFileDropzone"
      ref="additionalFileDropzone"
      :options="additionalDropzoneOptions"
      @vdropzone-sending="attachJWT"
      @vdropzone-complete="prepareUploadedFile"
    />

    <template slot="modal-footer">
      <button id="btnCancel" class="btn btn-danger" @click="emitCloseEvent">
        Cancel
      </button>

      <button
        id="btnAdd"
        class="btn btn-primary"
        @click="emitFileEvent"
        :disabled="usedAllFileTypes || !uploadedFileName.renamed"
      >
        Add
      </button>
    </template>
  </b-modal>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { mapGetters } from "vuex";

export default {
  components: {
    VueDropzone: vue2Dropzone,
  },

  props: {
    fileTypes: {
      type: Array,
    },
  },

  data() {
    return {
      showModal: true,
      error: {
        show: false,
        message: "",
      },

      // DropZone options
      additionalDropzoneOptions: {
        url: `${window.location.protocol}//${document.location.hostname}:8809/tempfile`,
        params: {
          dest: "migrate",
          random: 1,
        },
        maxFiles: 1,
        uploadMultiple: false,
        thumbnailWidth: 150,
        maxFilesize: 20000,
        timeout: 600000, // Override default of 30 seconds to 10 minutes
      },

      selectedFileType: {},
      usedAllFileTypes: false,
      uploadedFileName: {
        original: "",
        renamed: "",
      },
    };
  },

  computed: {
    ...mapGetters(["getToken"]),
  },

  mounted() {
    // Check if file type isn't disabled before setting it as the selected type.
    // Continue checking until we run out of options
    for (let i = 0; i < this.fileTypes.length; i++) {
        const fileType = this.fileTypes[i];

        if (!fileType.disabled) {
            this.selectedFileType = fileType;
            return;
        }
    }

    // Otherwise assume that all file types have been used up
    this.usedAllFileTypes = true;
  },

  methods: {
    // Attach JWT to Dropzone
    attachJWT(_file, xhr) {
      xhr.setRequestHeader('Authorization', `Bearer ${this.getToken}`);
    },

    // Change the selected file type if it isn't disabled
    changeSelectedFileType(file) {
      if (!file.disabled) {
        const foundFile = this.fileTypes.find((f) => f.type === file.type);
        if (foundFile) {
            this.selectedFileType = file;
        }
      }
    },

    // This function gets called once the file has been uploaded to the server
    prepareUploadedFile(event) {
      // Check for error
      if (event.status === "error") {
        this.error.show = true;
        this.error.message = "There was an error uploading your file...";

        // Remove all files from the Dropzone
        if (this.$refs.additionalFileDropzone) {
          this.$refs.additionalFileDropzone.removeAllFiles();
        }

        return;
      }

      // Set the original and server-side renamed file name
      if (event.xhr) {
        const response = JSON.parse(event.xhr.response);

        this.uploadedFileName.original = response.oldName;
        this.uploadedFileName.renamed = response.newName;
      }
    },

    emitFileEvent() {
      // Construct the data that will be returned in an event
      const data = {
        fileName: this.uploadedFileName.original,
        newFileName: this.uploadedFileName.renamed,
        fileLabel: this.selectedFileType.name,
        fileType: this.selectedFileType.type,
      };

      this.$emit("file", data);
      this.emitCloseEvent();
    },

    // Emit close event to parent
    emitCloseEvent() {
      this.$emit("close");
    },
  },
};
</script>