// Check if the user is allowed to download a specific LLD type
exports.checkAllowedLLD = function (lldType, groupProfileLLD) {
    // Set to all
    if (groupProfileLLD == '')
    {
        return true;
    }

    let userAllowedTypes = groupProfileLLD;

    // Check if allowed types is an array. If not, put it into one
    if (!Array.isArray(userAllowedTypes)) {
        userAllowedTypes = [userAllowedTypes];
    }

    return userAllowedTypes.find(type => type === lldType);
}