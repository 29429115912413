<template>
  <div>
    <!-- Show loading/waiting spinner -->
    <div class="col p-6" v-if="events.length === 0 && !showTimeout">
      <div class="d-flex justify-content-center">
        <FadeLoader :loading="true" color="#5e72e4" />
      </div>
      
      <p class="text-center pt-6">Waiting for schedule to begin...</p>
    </div>

    <!-- Error alert -->
    <div v-if="error.show" class="alert alert-danger">
      <p>{{ error.message }}</p>
    </div>

    <!-- Showcase the events data -->
    <div class="container p-2">
      <table v-if="events.length > 0 && !showTimeout" class="table align-items-center table-flush">
        <thead>
          <tr>
            <th class="w-25">Timestamp</th>
            <th>Message</th>
          </tr>
        </thead>
              
        <tbody>
          <tr v-for="(event, i) in events.slice(0, 6)" :key="event.index" :class="determineColour(events[i].eventType)">
            <td>
              {{ event.date | parseDate }}
            </td>
            <td :title="event.eventMsg" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 200px;">
              {{ event.eventMsg }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Show timeout error -->
    <div class="text-center" v-if="showTimeout">
      <h1 class="text-danger">{{ action }} failed!</h1>
      <p>A error or timeout has occurred. Please contact your provider for more details.</p>
    </div>

    <div class="d-flex justify-content-center" v-if="(error.show || showTimeout)">
      <!-- Add ability to download progress logs -->
      <button class="btn btn-primary" id="btnDownloadEventHistory" @click="getEventsAsCsv">
        Download Event History (.csv)
      </button>
    </div>

    <!-- Ability to start over -->
    <button id="btnReset" type="button" class="btn btn-link mt-4 mx-auto d-block" v-if="error.show || showTimeout" @click="$emit('retry')">
      Clear {{ action.toLowerCase() }} and start over?
    </button>

    <!-- Report preparation modal overlay -->
    <b-modal id="loadingModal" v-model="isDownloading" hide-backdrop centered hide-header-close hide-footer no-close-on-esc no-close-on-backdrop cancel-title>
      <div class="row">
        <div class="col">
          <img class='mx-auto d-block' :src="getImgUrl('pleasewait')" alt="logo">   
        </div>
      </div>

      <br>

      <div class="row text-center">
        <div class="col">
          <h1>Your report is being prepared...</h1>
          <p>This may take some time, so please wait.</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";

import eventService from "@/api/eventService.js";
import { forceDownload } from "@/utils/downloadFile.js";
import { getImgUrl } from "@/utils/images.js";

export default {
  name: "Events",
  data() {
    return {
      pollInterval: null,
      timeoutInterval: null,
      showTimeout: false,
      events: [],
      error: {
        message: "",
        show: false
      },
      isDownloading: false,
      getImgUrl
    }
  },
  components: {
    FadeLoader
  },
  props: {
    // Auditing or provisioning?
    action: {
      type: String
    },
    // Schedule/Job ID
    jobId: {
      type: [Number, String]
    },
    // Site ID
    siteId: {
      type: Number
    },
    // Timestamp
    timestamp: {
      type: Number
    },
  },
  filters: {
    parseDate: function(unixDate) {
      return DateTime.fromSeconds(unixDate).toFormat("D TT");
    },
  },
  created() {
    this.pollEvents();
  },
  beforeDestroy() {
    clearInterval(this.pollInterval);
    clearInterval(this.timeoutInterval);
  },
  methods: {
    pollEvents() {
      // If the timestamp prop provided is zero, use the current date as a replacement
      const timestamp = this.timestamp == 0 ? Math.floor(Date.now() / 1000) : this.timestamp;

      this.pollInterval = setInterval(async () => {
        const response = await eventService.listMessages(
          this.jobId,
          this.siteId,
          timestamp
        );

        if (response.data.rows) {
          this.events = response.data.rows;
          
          this.events.forEach(event => {
            // Clear the interval if audit or orchestration is complete
            if (event.eventType == "Success") {
              clearInterval(this.pollInterval);
              clearInterval(this.timeoutInterval);

              setTimeout(() => {
                this.$emit("completed")
              }, 2500)
            }

            // Check the event for any errors
            if (event.eventType == "Failed") {
              this.error.message = event.eventMsg;
              this.error.show = true;

              // Stop the intervals
              clearInterval(this.pollInterval);
              clearInterval(this.timeoutInterval);
              return;
            }
          })
        }
      }, 2000)

      this.timeoutInterval = setInterval(() => {
        const now = Math.floor(Date.now() / 1000);
        const maxTimeout = 300; // 5 minutes into seconds

        // If we've received no events within the maxTimeout value, expect a timeout
        if (this.events.length === 0) {
          if (now - timestamp > maxTimeout) {           
            clearInterval(this.pollInterval);
            clearInterval(this.timeoutInterval);    
            
            this.showTimeout = true;
            this.$emit("timeout");
          }
        }

        // If we do have some events, but it has been 2 minutes since the last timestamp,
        // expect a timeout
        if (this.events[0] && now - this.events[0].date > maxTimeout) {         
          clearInterval(this.pollInterval);
          clearInterval(this.timeoutInterval);

          this.showTimeout = true;
          this.$emit("timeout");
        }
      }, 2000)
    },

    // Determine class colour based on event type
    determineColour(type) {
      switch (type.toLowerCase()) {
        case "fail", "error": {
          return "table-danger"
        }
        case "warning": {
          return "text-warning"
        }
        case "success": {
          return "table-success";
        }
      }
    },

    // Returns all events within a CSV for the current job.
    async getEventsAsCsv() {
      this.isDownloading = true;
      try {
        const response = await eventService.listMessages(this.jobId, this.siteId, null, true);
        forceDownload(`UCentric ZT Events.xlsx`, response.data);
      } finally {
        this.isDownloading = false;
      }
    }
  }
};
</script>
