/*!

=========================================================
* Vue Argon Dashboard - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ArgonDashboard from './plugins/argon-dashboard'
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

// Custom CSS
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import "vue-multiselect/dist/vue-multiselect.min.css";

import store from "@/store";

Vue.config.productionTip = false

Vue.use(ArgonDashboard)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(require('vue-cookies'))

// Load existing information into Vuex store
store.dispatch("initialize");

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
