<template>
    <div v-if="getPartnerTypes().length > 0" class="row">
        <div class="col mb-3">
            <h3>Select partner type</h3>

            <base-dropdown>
                <base-button slot="title" id="dropPartner" type="secondary" class="dropdown-toggle">
                    {{ selectedPartner || "Select a partner type" }}
                </base-button>

                <!-- Get @click event working -->
                <li v-for="partner in getPartnerTypes()" :key="partner" id="dropItemPartner">
                    <a class="dropdown-item" @click="updatePartnerType(partner)">
                        {{ partner }}
                    </a>
                </li>
            </base-dropdown>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        profileData: {
            default: {}
        }
    },
    data() {
        return {
            selectedPartner: ""
        }
    },
    methods: {
        getPartnerTypes() {
            return this.profileData && this.profileData.SPAPartnerList && this.profileData.SPAPartnerList.split(',') || [];
        },
        updatePartnerType(partner) {
            this.selectedPartner = partner;
            this.$emit('updated', this.selectedPartner);
        }
    }
}

</script>