import authService from "@/api/authService";
import store from "@/store";
import Vue from "vue";

// Refreshes the JWT Token
const refreshNewToken = async () => {

    // Ignore if we are in SSO mode.
    const session = Vue.$cookies.get("SSO_Session");
    if (session && session.Type) {
      return null
    }

    const accessToken = store.getters.getToken;
    const refreshToken = store.getters.getRefreshToken;

    if (accessToken && refreshToken) {
      try {
        const res = await authService.refresh(accessToken, refreshToken);
        const newAccessToken = res.data.accessToken;
        const newRefreshToken = res.data.refreshToken;

        // Update access token and refresh token store
        store.dispatch("login", {
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
        });

        return {
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
        };
      } catch (e) {
        // If we have an error refreshing tokens, logout and route to Login page
        store.dispatch("logout");
        window.location.reload();
      }
    }
    return null;
}

export { refreshNewToken }