<template>
    <div v-if="isAuditAdmin">
        <card>
            <!-- Top Row: Home Button - Title - Filters -->
            <b-row class="pb-4">
                <!-- Home Button -->
                <b-col cols="4">
                    <b-button id="btnHome" variant="primary" title="Return to home page" @click="$router.push('/')">
                        <i class="fas fa-home"></i>
                    </b-button>
                </b-col>

                <!-- Page Title -->
                <b-col cols="4">
                    <h1 class="text-center text-capitalize">Previous {{ showType }} Jobs</h1>
                </b-col>

                <!-- Filter Buttons -->
                <b-col cols="4" class="d-flex justify-content-end">
                    <b-button
                        v-if="showType != 'audit'"
                        @click="showType = 'audit'"
                        variant="primary"
                    >show audits</b-button>

                    <b-button
                        v-if="showType != 'orchestration'"
                        @click="showType = 'orchestration'"
                        variant="primary"
                    >show orchestrations</b-button>
                </b-col>
            </b-row>

            <!-- Previous Jobs Table -->
            <b-table
                id="jobs"
                :fields="tableHeaders"
                :items="showList"
                :per-page="perPageAmount"
                :current-page="currentPage"
                :busy="isLoading"
                striped
                hover
            >
                <!-- Loading Skeleton -->
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <strong>Loading...</strong>
                    </div>
                </template>

                <!-- Actions (Buttons) -->
                <template v-slot:cell(actions)="row">
                    <!-- Download HDL [if: audit] -->
                    <button
                        v-if="showType == 'audit'"
                        class="btn btn-primary btn-sm text-uppercase"
                        @click="downloadHLD(row.item)"
                    >hld <i class="fas fa-download"></i></button>

                    <!-- Download LLD [if: audit] -->
                    <button
                        v-if="showType == 'audit'"
                        class="btn btn-success btn-sm text-uppercase"
                        @click="downloadLLD(row.item)"
                    >lld <i class="fas fa-download"></i></button>

                    <!-- Download Provision Results [if: orchestration] -->
                    <button
                        v-if="showType == 'orchestration'"
                        class="btn btn-success btn-sm text-uppercase"
                        @click="downloadProvisionResults(row.item)"
                    >results <i class="fas fa-download"></i></button>

                    <!-- Download Event History [all] -->
                    <button
                        class="btn btn-secondary btn-sm text-uppercase"
                        @click="downloadEventLog(row.item)"
                    >event <i class="fas fa-download"></i></button>
                </template>
            </b-table>

            <!-- Previous Jobs Table Pagination -->
            <b-pagination
                v-model="currentPage"
                :total-rows="showList.length"
                :per-page="perPageAmount"
                aria-controls="scans"
                align="center"
                class="mt-4"
            />

            <!-- Downloading Report Modal -->
            <b-modal 
                v-model="isDownloading"
                hide-backdrop
                centered
                hide-header-close
                hide-footer
                no-close-on-esc
                no-close-on-backdrop
                cancel-title
            >
                <div class="row">
                    <div class="col">
                        <img class='mx-auto d-block' :src="getImgUrl('pleasewait')" alt="logo">
                    </div>
                </div>

                <br>

                <div class="row text-center">
                    <div class="col">
                        <h1>Your report is being prepared...</h1>
                        <p>This may take some time, so please wait.</p>
                    </div>
                </div>
            </b-modal>
        </card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UCentric from "@/views/ucentric";
import jobService from "@/api/jobService";
import appService from "@/api/appService.js";
import { forceDownload } from "@/utils/downloadFile.js";
import { version } from "../../package.json";
import { formatFileName } from "../mixins/file/format";
import { getImgUrl } from "@/utils/images.js";

export default {
    data() {
        return {
            formatFileName,
            version,
            getImgUrl,
            isLoading: false,
            isDownloading: false,
            showType: 'audit',
            auditList: [],
            orchestrationList: [],
            showNumberOfPreviousJobs: 100,
            perPageAmount: 10,
	        currentPage: 1,
        }
    },

    computed: {
        // VueX getters
        ...mapGetters([
            "isAuditAdmin",
            "getAppInfo",
            "getHandsets",
            "getGroupProfile",
        ]),

        // Show list of jobs using filters
        showList() {
            if(this.showType == 'orchestration') {
                return this.orchestrationList;
            }

            return this.auditList;
        },

        // Display table headers based off filters
        tableHeaders() {
            let tableHeaders = [
                {
                    label: "Customer Name",
                    key: "siteName"
                },
                {
                    label: "Schedule ID",
                    key: "scheduleId"
                },
                {
                    label: "Site ID",
                    key: "siteId"
                },
                {
                    label: "Mode",
                    key: "mode"
                },
                {
                    label: "Date",
                    key: "scandate"
                },
                {
                    label: "Actions",
                    key: "actions"
                }
            ];

            if(this.showType == 'audit') {
                tableHeaders[0].key = 'siteName';
            }
            else if(this.showType == 'orchestration') {
                tableHeaders[0].key = 'customer';
                tableHeaders.splice(2, 1);
            }

            return tableHeaders;
        }
    },

    async created() {
        // Route guard the component using Audit Admin Permission
        if(!this.isAuditAdmin) {
            this.$router.push('/');
        }
    },

    async mounted() {
        // Fetch app variables
        await this.getAppVariables();

        // Fetch Previous Job list
        await this.getPreviousJobList();
    },

    methods: {
        // Map VueX Actions
        ...mapActions([
            "setAppInfo"
        ]),

        // Get App Variables and Set in VueX
        async getAppVariables() {
            try {
                const res = await appService.getAppVariables();
                this.setAppInfo(res.data);
            }
            catch (error) {
                console.log("An error occurred fetching app variables.");
            }
        },

        // Gets list of jobs
        async getPreviousJobList() {
            try {
                this.isLoading = true;
                
                const response = await jobService.getPreviousJobList(this.showNumberOfPreviousJobs);
                
                // rows = audits
                this.auditList = response.data.rows || [];

                // rows2 = orchestrations
                this.orchestrationList = response.data.rows2 || [];
            }
            catch(error) {
                console.error(error);
            }
            finally {
                this.isLoading = false;
            }
        },

        // Download HLD using passed in row
        async downloadHLD(item) {
            try {
                this.isDownloading = true;

                const response = await jobService.downloadHLD(
                    '_Default_Template.docx',
                    item.siteId,
                    item.scandate,
                );

                let fn = response.headers["content-disposition"].split("filename=");

                if(this.getAppInfo?.global?.HLDFilenameFormat) {
                    const items = {
                        customerName: item.siteName,
                        scanDate: item.scandate,
                        currentDate: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '').replace(/:/g, '-'),
                    };

                    fn = `${this.formatFileName(this.getAppInfo.global.HLDFilenameFormat, items)}.docx`;
                }
                else if (fn.length > 0) {
                    fn = fn[1].split(";")[0].replace(/['"]+/g, '');
                }
                else {
                    fn = "AuditSummary.docx";
                }

                forceDownload(fn, response.data);
            }
            catch(error) {
                console.error(error);
            }
            finally {
                this.isDownloading = false;
            }
        },

        // Download LLD using passed in row
        async downloadLLD(item) {
            try {
                this.isDownloading = true;

                let options = UCentric.migrationSettings;

                options.source.scans.push({
                    siteId: item.siteId,
                    scanDate: item.scandate
                });

                options.checks.checkHandsets = this.getHandsets || "";

                const isSPALLDArray = Array.isArray(this.getGroupProfile.SPALLD);
                const hasNonEmptyString = isSPALLDArray && this.getGroupProfile.SPALLD[0]?.length > 0;

                options.output.outputFormat = hasNonEmptyString ? this.getGroupProfile.SPALLD[0] : "Excel";
                options.spa = this.version;
                
                const response = await jobService.downloadLLD(
                    item.siteId,
                    JSON.stringify(options)
                );

                let fn = response.headers["content-disposition"].split("filename=");

                if(this.getAppInfo?.global?.LLDFilenameFormat) {

                    const items = {
                        customerName: item.siteName,
                        scanDate: item.scandate,
                        currentDate: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '').replace(/:/g, '-'),
                    };

                    fn = `${this.formatFileName(this.getAppInfo.global.LLDFilenameFormat, items)}.xlsx`;
                }
                else if (fn.length > 0) {
                    fn = fn[1].replace(/['"]+/g, '');
                }
                else {
                    fn = "ProvisioningTemplate.xlsx";
                }

                forceDownload(fn, response.data);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                this.isDownloading = false;
            }
        },

        // Download LLD using passed in row
        async downloadProvisionResults(item) {
            try {
                this.isDownloading = true;
                
                const response = await jobService.downloadProvisionResults(item.scheduleId);

                let fn = response.headers["content-disposition"].split("filename=")

                if (fn.length > 0) {
                    // Split further and remove double quotes
                    fn = fn[1].split(";")[0].replace(/['"]+/g, '');
                }
                else {
                    fn = "Bulkloader.xlsx";
                }

                forceDownload(fn, response.data);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                this.isDownloading = false;
            }
        },

        // Download LLD using passed in row
        async downloadEventLog(item) {
            try {
                this.isDownloading = true;

                const response = await jobService.downloadEventLog(
                    item.scheduleId,
                    item.siteId,
                    null,
                    true
                );
                
                forceDownload(`UCentric ZT Events.xlsx`, response.data);
            }
            catch(error) {
                console.error(error);
            }
            finally {
                this.isDownloading = false;
            }
        },
    }
}
</script>
