<template>
  <!-- Download document picker -->
  <div class="col-12">
    <card header-classes="bg-transparent">
      <h2 class="text-center">Download {{ action }} Documents</h2>

      <div class="d-flex justify-content-center">
        <div v-for="file in files" :key="file.filename" class="m-2">
          <!-- Ability to select between multiple LLD types if available -->
          <div v-if="file.type === 'lld' && groupProfile && groupProfile.SPALLD != ''">
            <base-dropdown class="w-100">
              <base-button slot="title" id="dropPlatformType" type="success" class="dropdown-toggle w-100">
                <img style="max-width: 16px; margin-right: 5px" :src="getImgUrl('excel')" :alt="file.docType">
                {{ selectedLLD ? selectedLLD : "Select Low Level Design/Bulk Loader" }}
              </base-button>

              <b-dropdown-item v-for="lld in getLLDTypes" :key="lld.id" id="btnSelectOrchestration"
                v-show="checkAllowedLLD(lld.id, groupProfile.SPALLD)" @click="downloadReport(file, lld.id, lld.name)">
                <span>{{ lld.name }}</span>
              </b-dropdown-item>
            </base-dropdown>
          </div>
          <button v-else @click="downloadReport(file)" :class="`btn btn-${getButtonColour(file.docType)}`"
            :id="getId(file.type)">
            <img style="max-width: 16px; margin-right: 5px" :src="getImgUrl(file.docType)" :alt="file.docType">
            {{ file.document }}
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <!-- Add ability to download progress logs -->
        <button class="btn btn-primary" id="btnDownloadEventHistory" @click="getEventsAsCsv">
          Download Event History (.csv)
        </button>
      </div>

      <slot name="action" />
    </card>

    <!-- Report preparation modal overlay -->
    <b-modal id="loadingModal" v-model="isDownloading" hide-backdrop centered hide-header-close hide-footer
      no-close-on-esc no-close-on-backdrop cancel-title>
      <div class="row">
        <div class="col">
          <img class='mx-auto d-block' :src="getImgUrl('pleasewait')" alt="logo">
        </div>
      </div>

      <br>

      <div class="row text-center">
        <div class="col">
          <h1>Your report is being prepared...</h1>
          <p>This may take some time, so please wait.</p>
        </div>
      </div>
    </b-modal>

    <!-- Error modal -->
    <b-modal v-model="isError" hide-backdrop centered ok-only>
      <template slot="modal-title">
        <h2 class="modal-title">Error!</h2>
      </template>

      <p>{{ error }}</p>
    </b-modal>
  </div>
</template>

<script>
import auditService from "@/api/auditService.js";
import provisioningService from "@/api/provisioningService.js";
//import eventService from "@/api/eventService.js";
import { getImgUrl } from "@/utils/images.js";
import { forceDownload } from "@/utils/downloadFile.js";
import { mapGetters } from "vuex";
import { checkAllowedLLD } from "@/utils/LLDUtils.js"
import { formatFileName } from "../mixins/file/format";
import jobService from "@/api/jobService";

export default {
  name: "DownloadReports",

  props: {
    files: Array,
    action: String,
    scheduleId: Number,
    siteId: Number,
    groupProfile: Object,
    overrideFileInfo: {
      validator: function(value) {
        return typeof value === 'object' || typeof value === 'boolean';
      },
      default: false,
    }
  },

  data() {
    return {
      formatFileName,
      isDownloading: false,
      isError: false,
      error: "",
      getImgUrl,
      selectedLLD: "",
    }
  },

  computed: {
    ...mapGetters(["getLLDTypes", "getAppInfo"]),
  },

  methods: {
    checkAllowedLLD,

    // Download report according to type
    async downloadReport(file, type = "Excel", label) {
      // Show loading overlay
      this.isDownloading = true;

      // Set the LLD label in use
      this.selectedLLD = label;

      switch (file.type) {
        case "hld": {
          try {
            const response = await auditService.mergeDoc(
              file.parameters.docTemplate,
              file.parameters.siteId,
              file.parameters.scanDate,
              file.parameters.version,
              file.parameters.fallbackHld
            );

            // Extract the file name from disposition header
            let fn = response.headers["content-disposition"].split("filename=");

            if(this.overrideFileInfo) {
              fn = `${this.overrideFileName('hld')}.docx`;
            }
            else if (fn.length > 0) {
              // Split further and remove double quotes
              fn = fn[1].split(";")[0].replace(/['"]+/g, '');
            }
            else {
              fn = "AuditSummary.docx";
            }

            forceDownload(fn, response.data);
          }
          catch (e) {
            // Hide downloading modal and show error
            this.isDownloading = false;
            this.isError = true;
            this.error = "An error occurred downloading the report, please try again";
          }

          break;
        }
        case "lld": {
          // Modify the settings parameter
          const settingsParameters = file.parameters.settings;
          settingsParameters.output.outputFormat = type;

          try {
            const response = await auditService.exportAudit(
              file.parameters.siteId,
              JSON.stringify(settingsParameters)
            );

            // Extract the file name from disposition header
            let fn = response.headers["content-disposition"].split("filename=");

            if(this.overrideFileInfo) {
              fn = `${this.overrideFileName('lld')}.xlsx`;
            }
            else if (fn.length > 0) {
              // Split further and remove double quotes
              fn = fn[1].replace(/['"]+/g, '');
            }
            else {
              fn = "ProvisioningTemplate.xlsx";
            }

            forceDownload(fn, response.data);
          } catch (e) {
            // Hide downloading modal and show error
            this.isDownloading = false;
            this.isError = true;
            this.error = "An error occurred downloading the report, please try again";
          }

          break;
        }
        case "prov": {
          try {
            // Extract the file name from disposition header
            const response = await provisioningService.getJob(file.parameters.jobId);
            let fn = response.headers["content-disposition"].split("filename=")

            if (fn.length > 0) {
              // Split further and remove double quotes
              fn = fn[1].split(";")[0].replace(/['"]+/g, '');
            }
            else {
              fn = "Bulkloader.xlsx";
            }

            forceDownload(fn, response.data);
          } catch (e) {
            // Hide downloading modal and show error
            this.isDownloading = false;
            this.isError = true;
            this.error = "An error occurred downloading the report, please try again";
          }

          break;
        }
      }

      // Hide loading overlay
      this.isDownloading = false;
    },

    // Return the formatted file name
    overrideFileName(type) {
      let fileFormat;

      if(type === 'hld') {
        fileFormat = this.getAppInfo.global.HLDFilenameFormat;
      }
      
      else if(type === 'lld') {
        fileFormat = this.getAppInfo.global.LLDFilenameFormat;
      }

      let overrideFileInfo = this.overrideFileInfo;

      overrideFileInfo.currentDate = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '').replace(/:/g, '-')

      return this.formatFileName(fileFormat, overrideFileInfo);
    },

    // Determine button colour
    getButtonColour(filetype) {
      switch (filetype) {
        case "word": {
          return "primary"
        }
        case "excel": {
          return "success"
        }
        case "download": {
          return "success"
        }
      }
    },

    // Create an ID attribute
    getId(type) {
      let id = "btnDownload";
      const upperCaseType = type.charAt(0).toUpperCase() + type.slice(1);
      return `${id}${upperCaseType}`;
    },

    // Returns all events within a CSV for the current job.
    async getEventsAsCsv() {
      this.isDownloading = true;
      try {


        const response = await jobService.downloadEventLog(this.scheduleId,this.siteId,null,true);
                


        //const response = await eventService.listMessages(this.scheduleId, this.siteId, null, true);
        forceDownload(`UCentric ZT Events.xlsx`, response.data);
      } finally {
        this.isDownloading = false;
      }

    }
  },
};
</script>
