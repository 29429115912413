import apiClient from "./baseService";

const CUSTOM_URL = `${window.location.protocol}//${document.location.hostname}:8809`

export default {
	getJobs(data, config) {
		return apiClient.post(`${CUSTOM_URL}/api/jobs`, data, config);
	},
	getJob(id) {
		return apiClient.get(`${CUSTOM_URL}/api/jobs/${id}/file`, {
			responseType: 'arraybuffer'
		})
	},
	getProvisioningTypes() {
		return apiClient.get("/orchestrationVendors");
	}
}