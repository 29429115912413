import apiClient from "./baseService";

export default {
	login(username, password) {
		return apiClient.post("/v2/login", {
			username,
			password,
		});
	},
	refresh(accessToken, refreshToken) {
		return apiClient.post("/v2/refresh", {
			accessToken,
			refreshToken,
		});
	},
    validateSsoSession(session) {
        return apiClient.post(`/auth/validate`, {
            SSO_Session: session
        });
    },
	ssoDetails() {
		return apiClient.get('/sso');
	},
	bannerText() {
		return apiClient.get('/getbanner')
	},
	getTargetProfiles() {
		return apiClient.get('/returnlist?q=15');
	}
}