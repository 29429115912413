<template>
	<div class="h-100">
		<div class="container h-100">
			<div class="d-flex align-items-center justify-content-center h-100">
				<div class="d-flex flex-column">
					<!-- Loading -->
					<div v-if="isLoading">
						<p class="text align-self-center p-3">Please wait...</p>
					</div>

					<!-- Error -->
					<div v-if="!isLoading && error">
						<p class="p-3">{{ error }}</p>
						<router-link id="btnBack" to="/" class="btn btn-primary btn-block">Go Back</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import oauthService from '@/api/oauthService';

export default {
	methods: {
		...mapActions([
			"setExternalAuth"
		])
	},
	computed: {
		...mapGetters([
			"getProvisioningTypes",
			"getPABXTypes"
		])
	},
	data() {
		return {
			isLoading: true,
			error: ""
		}
	},
	async mounted() {
		await this.$store.dispatch("initializeProvisioningTypes");
		await this.$store.dispatch("initializePABXTypes");

		const settingsIdentifier = this.$route.query.state;

		try {
			let settings = JSON.parse(localStorage.getItem(settingsIdentifier));
			if (!settings) {
				this.error = 'No external auth expected.';
				return;
			}

			const tokenRequestResponse = await oauthService.exchangeToken(settings, this.$route.query.code);
			let userInfo = tokenRequestResponse;

			if (settings.oauthEndpoints.userinfo_endpoint && !settings.oauthEndpoints.proxyAuth) {
				Object.assign(userInfo, await oauthService.getUserProfile(settings, userInfo.access_token));
			}

			userInfo.uc_type = settings.extraTokenParams.type;
			userInfo.identifier = settings.extraTokenParams.identifier.toUpperCase();

			let validType = settings.extraTokenParams.type === 'Audit' ?
				this.getPABXTypes.find(o => o.databaseType.toUpperCase() == userInfo.identifier) :
				this.getProvisioningTypes.find(o => o.name.toUpperCase() == userInfo.identifier);

			if (!validType) {
				this.error = "No supported authentication provider was provided."
				return
			}

			this.setExternalAuth(userInfo);
			this.$router.push("/");
		} catch (error) {
			if (error.response && error.response.data && error.response.data.error) {
				this.error = error.response.data.error;
			} else {
				this.error = `Unknown error has occured. ${error}`;
			}
		} finally {
			localStorage.removeItem(settingsIdentifier);
			this.isLoading = false;
		}
	}
}
</script>