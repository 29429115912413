import axios from "axios";
import store from "@/store";

import authService from "@/api/authService";
import { refreshNewToken } from "@/utils/authenticationHelper";
import Vue from "vue";

export const defaultUrl = `${window.location.protocol}//${document.location.hostname}:8809`;

const apiClient = axios.create({
  baseURL: defaultUrl,
  headers: {
    UcentricSPA: "1",
    SecProfile: "999999999; expires=31 Dec 2199 00:00:00 GMT",
  },
});

apiClient.interceptors.request.use((config) => {
    // Attach access token to Authorization header on every request (if possible)
    const accessToken = store.getters.getToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
}, (error) => {
  return Promise.reject(error);
});


apiClient.interceptors.request.use(
  async (config) => {
    // Depending on the request URL, carry out additional SSO checks (validation)
    const url = config.baseURL + config.url;
    const checkRoutes = ["detectVendorBackupType", "ammergedoc", "exportaudit", "/api/jobs"];

    const session = Vue.$cookies.get("SSO_Session");
    if (session && session.Type) {
      for (let i = 0; i < checkRoutes.length; i++) {
        const route = checkRoutes[i];

        if (url.includes(route)) {
          try {
            const res = await authService.validateSsoSession(session);
            store.dispatch("login", res.data);
          } catch (e) {
            if (e.response && e.response.data.location) {
              window.location.href = e.response.data.location;
              return Promise.reject(e);
            }
          }
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Check responses for 401 status code and refresh JWT if needed
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Handle unauthorised request if it isn't already being retried
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      var newTokens = await refreshNewToken();
      if (newTokens) {
        // Update the Authorization header with the new access token
        originalRequest.headers.Authorization = `Bearer ${newTokens.refreshToken}`;
        return axios(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

// Check every request response to make sure a response is present
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // If there is no response, assume server is offline
    if (!error.response) {
      const event = new Event("offline");
      document.dispatchEvent(event);

      return;
    }

    return Promise.reject(error);
  }
);

export default apiClient;
