<template>
  <div>
	<!-- SSO Validation -->
	<SSO v-if="getSSO.Type && isValidatingSSO" @validated="isValidatingSSO = false" />

	<!-- Loading modal -->
    <b-modal id="loadingModal" v-model="isLoading" v-if="isLoading && !isValidatingSSO" hide-backdrop centered hide-header-close hide-footer no-close-on-esc no-close-on-backdrop cancel-title>
        <img
          class="mx-auto d-block"
          :src="getImgUrl('pleasewait')"
          alt="loading"
        />
        <p class="mt-4 text-center">Loading sites, please wait...</p>
    </b-modal>

	<!-- Error modal -->
    <b-modal v-model="showError" hide-backdrop centered ok-only>
      <template slot="modal-title">
        <h2 class="modal-title">Error!</h2>
      </template>

      <template>
        <p>{{ errorMessage }}</p>
      </template>
    </b-modal>

	<base-header type="gradient-light" class="p-4 pb-8">
        <div class="row justify-content-center pb-4">
        	<img width="300px" :src="getImgUrl(getGroupProfile && getGroupProfile.SPALogo ? getGroupProfile.SPALogo : 'ucentric')" alt="logo">
        </div>

        <div class="row justify-content-center">
        	<div class="col-xl-6">
                <tabs tabNavClasses="nav-fill flex-column flex-sm-row">
					<!-- Sites table -->
					<Card>
      					<template>
							<b-row class="pb-4">
    							<b-col cols="4">
									<b-button id="btnHome" variant="primary" title="Return to home page" @click="$router.push('/')">
										<i class="fas fa-home"></i>
									</b-button>
								</b-col>
    							<b-col cols="4">
									<h1 class="text-center">Manage Sites</h1>
								</b-col>
  							</b-row>

							<b-table
      							id="sites"
								:fields="siteFields"
      							:items="sites"
      							:per-page="perPage"
      							:current-page="currentPage"
								:busy="isLoading"
								striped
								hover
								@row-clicked="openSite"
    						>
								<template #table-busy>
        							<div class="text-center text-primary my-2">
          								<strong>Loading...</strong>
        							</div>
      							</template>

								<template v-slot:cell(actions)="row">
        							<button id="btnRemoveSiteAudit" title="Remove site and all audits" class="btn btn-danger btn-sm" @click="showConfirmDeleteModal(row.item.siteId)">
          								✖
        							</button>
      							</template>
							</b-table>

							<b-pagination
      							v-model="currentPage"
      							:total-rows="sites.length"
      							:per-page="perPage"
      							aria-controls="sites"
								align="center"
								class="mt-4"
    						></b-pagination>
      					</template>
    				</Card>
                </tabs>
            </div>
        </div>
    </base-header>

	<!-- Site scans/audits modal -->
	<b-modal v-model="openAdditionalScansModal" size="lg" hide-backdrop centered hide-footer>
		<template slot="modal-title">
			<h1>Audits for Site ID: {{ selectedSiteId }}</h1>
		</template>

		<template>
			<b-table
      			id="scans"
				:fields="scanFields"
      			:items="scanDates"
      			:per-page="perPage2"
      			:current-page="currentPage2"
				:busy="isLoadingScans"
				striped
				hover
    		>
				<template #table-busy>
          			<div class="text-center text-primary my-2">
          				<strong>Loading...</strong>
        			</div>
				</template>

				<template v-slot:cell(action)="row">
					<button id="btnRemoveAudit" title="Remove audit" class="btn btn-danger btn-sm" @click="showConfirmDeleteScanModal(row.item.scanDate)">
          				✖
        			</button>
      			</template>
			</b-table>

			<b-pagination
      			v-model="currentPage2"
      			:total-rows="scanDates.length"
      			:per-page="perPage2"
      			aria-controls="scans"
				align="center"
				class="mt-4"
    		></b-pagination>
		</template>
	</b-modal>

    <!-- Success Modal -->
    <b-modal v-model="showSuccessModal" hide-backdrop centered>
      <template slot="modal-title">
        <h1>Success!</h1>
      </template>

      <p>{{ successMessage }}</p>

      <template slot="modal-footer">
        <button id="btnClose" @click="showSuccessModal = false" class="btn btn-primary">
          Close
        </button>
      </template>
    </b-modal>

    <!-- Confirm remove sites and audits modal -->
    <b-modal v-model="showConfirmRemoveModal" hide-backdrop centered no-close-on-esc no-close-on-backdrop>
      <template slot="modal-title">
        <h1>Are you sure?</h1>
      </template>

      <p>
        You are about to remove a site and all it's audits, are you sure you
        want to do this?
      </p>

      <template slot="modal-footer">
			<base-button id="btnCancel" class="ml-auto" type="link" @click="showConfirmRemoveModal = false" :disabled="isDeleting">Cancel</base-button>
        	<base-button
        		type="danger"
          		@click="removeSiteAndAudits()"
				style="width: 140px "
				:disabled="isDeleting"
				id="btnConfirmDelete"
        	>
				<svg v-if="isDeleting" width="20" height="20" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
    				<defs>
        				<linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
            				<stop stop-color="#fff" stop-opacity="0" offset="0%"/>
            				<stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/>
            				<stop stop-color="#fff" offset="100%"/>
        				</linearGradient>
    				</defs>
    				<g fill="none" fill-rule="evenodd">
        				<g transform="translate(1 1)">
            				<path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                				<animateTransform
                    				attributeName="transform"
                    				type="rotate"
                    				from="0 18 18"
                    				to="360 18 18"
                    				dur="0.9s"
                    				repeatCount="indefinite" />
            				</path>
            				<circle fill="#fff" cx="36" cy="18" r="1">
                				<animateTransform
                				    attributeName="transform"
                				    type="rotate"
                				    from="0 18 18"
                				    to="360 18 18"
                    				dur="0.9s"
                    				repeatCount="indefinite" />
            				</circle>
        				</g>
    				</g>
				</svg>
        		<span v-else>Delete Site</span>
        	</base-button>
      </template>
    </b-modal>

	<!-- Remove individual audit/scan -->
	<b-modal v-model="showConfirmRemoveScanModal" hide-backdrop centered :no-close-on-esc="isDeleting" :no-close-on-backdrop="isDeleting" :hide-header-close="isDeleting">
		<template slot="modal-title">
        	<h1>Are you sure?</h1>
      	</template>

        <span>
          	You are about to delete an audit from <strong>{{ selectedScanDate | formatDate }}</strong>. Are you sure you want to do this?
        </span>

		<template slot="modal-footer">
			<base-button id="btnCancel" class="ml-auto" type="link" @click="showConfirmRemoveScanModal = false" :disabled="isDeleting">Cancel</base-button>
        	<base-button
        		type="danger"
				style="width: 140px "
				@click="removeAudit"
				:disabled="isDeleting"
				id="btnConfirmDelete"
        	>
				<svg v-if="isDeleting" width="20" height="20" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
    				<defs>
        				<linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
            				<stop stop-color="#fff" stop-opacity="0" offset="0%"/>
            				<stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/>
            				<stop stop-color="#fff" offset="100%"/>
        				</linearGradient>
    				</defs>
    				<g fill="none" fill-rule="evenodd">
        				<g transform="translate(1 1)">
            				<path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                				<animateTransform
                    				attributeName="transform"
                    				type="rotate"
                    				from="0 18 18"
                    				to="360 18 18"
                    				dur="0.9s"
                    				repeatCount="indefinite" />
            				</path>
            				<circle fill="#fff" cx="36" cy="18" r="1">
                				<animateTransform
                				    attributeName="transform"
                				    type="rotate"
                				    from="0 18 18"
                				    to="360 18 18"
                    				dur="0.9s"
                    				repeatCount="indefinite" />
            				</circle>
        				</g>
    				</g>
				</svg>
        		<span v-else>Delete Audit</span>
        	</base-button>
      </template>
	</b-modal>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { getImgUrl } from "@/utils/images";
import { mapActions, mapGetters } from "vuex";

import auditService from "@/api/auditService.js";
import authService from "@/api/authService.js";

// Bootstrap Vue
import { BTable, BPagination } from "bootstrap-vue";
import Card from "@/components/Argon/Card.vue";
import SSO from "@/components/SSO.vue";

export default {
  name: "ManageSites",
  data() {
    return {
      isLoading: true,
	  isLoadingScans: false,
	  isValidatingSSO: false,

      sites: [],
	  scanDates: [],

      selectedSiteId: "",
	  selectedScanDate: "",
      showConfirmRemoveModal: false,
	  showConfirmRemoveScanModal: false,
	  openAdditionalScansModal: false,

      showSuccessModal: false,
	  successMessage: "",
	 
	  isDeleting: false,

	  showError: false,
	  errorMessage: "",

      getImgUrl,

	  // Sites table
	  siteFields: [
		  {
			  label: "Site Name",
			  key: "siteName"
		  },
		  {
			  label: "Audits",
			  key: "scans"
		  },
		  {
			  label: "Actions",
			  key: "actions"
		  }
	  ],
	  perPage: 5,
      currentPage: 1,

	  // Scans table
	  scanFields: [
		  {
			  label: "Audit Date",
			  key: "scanDate"
		  },
		  {
			  label: "Action",
			  key: "action"
		  }
	  ],
	  perPage2: 5,
	  currentPage2: 1
    };
  },
  filters: {
    formatDate(date) {
      return DateTime.fromFormat(date, "yyyy-MM-dd HH-mm-ss").toHTTP();
    },
  },
  components: {
    Card,
	BTable,
	BPagination,
	SSO
  },
  computed: {
    ...mapGetters(["getDefaultNode", "getGroupProfile", "getSSO"])
  },
  methods: {
	...mapActions([
		"setSSO"
    ]),

    showConfirmDeleteModal(siteId) {
      this.showConfirmRemoveModal = true;
      this.selectedSiteId = siteId;
    },

	showConfirmDeleteScanModal(scanDate) {
		this.showConfirmRemoveScanModal = true;
		this.selectedScanDate = scanDate;
	},

	async openSite(event) {
		if (!this.showConfirmRemoveModal) {
			this.scanDates = [];
			this.selectedSiteId = event.siteId;
			this.openAdditionalScansModal = true;
			this.isLoadingScans = true;

			// Fetch and populate all scan dates for site
			try {
				const res = await auditService.getAllScanDates(event.siteId);
				if (res.data.rows[0].scanDates) {
					const scanDates = res.data.rows[0].scanDates;
					
					scanDates.forEach(date => {
						this.scanDates.push({ 'scanDate': date });
					})
				}
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoadingScans = false;
			}
		}
	},

    async removeSiteAndAudits() {
      try {
		this.isDeleting = true;

        await auditService.deleteSiteAndAudits(this.selectedSiteId);

		// Fetch updated sites
		const res = await auditService.getScanDates(this.getDefaultNode);
		this.sites = res.data.rows;

        this.showConfirmRemoveModal = false;
        this.showSuccessModal = true;
		this.isDeleting = false;
      } catch (e) {
        if (e.response) {
          this.showConfirmRemoveModal = false;
		  this.isDeleting = false;

          this.showError = true;
          this.errorMessage = e.response.data;

          return;
        }
      }

	  this.successMessage = "Site and related audits have been deleted."
    },

	async removeAudit() {
		const scans = [];

		try {
			// Attempt to delete audit
			this.isDeleting = true;

			await auditService.deleteAudit(this.selectedSiteId, this.selectedScanDate);

			// Fetch updated scans list
			const res = await auditService.getAllScanDates(this.selectedSiteId);
			const scanDates = res.data.rows[0].scanDates;
			scanDates.forEach(date => {
				scans.push({ 'scanDate': date });
			});

			// Fetch updated sites list
			const res2 = await auditService.getScanDates(this.getDefaultNode);
			this.sites = res2.data.rows;
		} catch (e) {
			this.showConfirmRemoveScanModal = false;
        	this.showSuccessModal = false;

			this.showError = true;
			this.errorMessage = e.response.data;
			return;
		} finally {
			this.isDeleting = false;
		}

		this.scanDates = scans;
		this.showConfirmRemoveScanModal = false;

		this.successMessage = "Audit has been deleted."
        this.showSuccessModal = true;
	}
  },
  async mounted() {
    // Fetch and set SSO information
	try {
        const res = await authService.ssoDetails();
        this.setSSO(res.data);

		if (this.getSSO.Type.length > 0) {
			this.isValidatingSSO = true;
		}
    } catch (e) {
    	console.log(e);
    }

	// Fetch a list of all scans for sites
	try {
		const res = await auditService.getScanDates(this.getDefaultNode);
		this.sites = res.data.rows;
	} catch (e) {
		console.log(e);
		return
	}

    this.isLoading = false;
  },
};
</script>