<template>
  <b-navbar toggleable="sm" type="dark" variant="gradient-light">
    <b-navbar-brand href="/">
      <span class="h4 mb-0 text-white d-none d-lg-inline-block">UCentric ZT</span>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav v-if="isLoggedIn" class="ml-auto">
        <b-nav-item-dropdown class="mb-0 text-sm font-weight-bold" right>
          <template slot="button-content">
            <i class="ni ni-single-02 mr-2"></i>
            <span>{{ getUsername }}</span>
          </template>

          <b-dropdown-header variant="dark">
            Welcome
          </b-dropdown-header>

          <b-dropdown-item @click="home">
            <b-icon icon="house-door-fill"></b-icon>
            <span>Home</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="isSPAAdmin" @click="manageSites">
            <i class="ni ni-settings-gear-65"></i>
            <span>Manage Sites</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="isAuditAdmin" @click="() => { $router.push('/jobs') }">
            <b-icon icon="hammer"></b-icon>
            <span>Jobs</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="isAuditAdmin" @click="$bvModal.show('lldModal')">
            <b-icon icon="file-spreadsheet"></b-icon>
            <span>Create sample LLD report</span>
          </b-dropdown-item>

          <!-- LLD dropdown information -->
          <b-modal id="lldModal" @ok="downloadSampleReport">
            <template #modal-title>
              Generate sample LLD
            </template>

            <P>Please choose an LLD to generate with sample data:</P>
            <select class="custom-select" v-model="selectedLLD">
              <option v-for="lld in getLLDTypes.filter(lld => checkAllowedLLD(lld.id, getGroupProfile.SPALLD))"
                :key="lld.id" :value="lld.id">{{
                  lld.name }}</option>
            </select>
          </b-modal>

          <b-dropdown-divider v-if="sso.Help || sso.MatrixUrl" />

          <b-dropdown-item v-if="sso.Help" :href="sso.Help" target="_blank">
            <i class="ni ni-bulb-61"></i>
            <span>Help</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="sso.MatrixUrl" :href="sso.MatrixUrl" target="_blank">
            <i class="ni ni-books"></i>
            <span>Compatibility Matrix</span>
          </b-dropdown-item>

          <b-dropdown-divider />
          <b-dropdown-item @click="logoutUser">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-header variant="dark">
            Service Status
          </b-dropdown-header>

          <b-dropdown-item v-for="service in serviceStatus" :key="service.name"
            :class="service.status === 'Stopped' ? 'dropdown-error' : 'dropdown-success'">
            <i :class="service.status === 'Stopped' ? 'ni ni-button-pause' : 'ni ni-button-play'"></i>
            <span>{{ service.description }}</span>
          </b-dropdown-item>

        </b-nav-item-dropdown>

      </b-navbar-nav>
    </b-collapse>

    <!-- Report preparation modal overlay -->
    <b-modal id="loadingModal" v-model="isDownloading" hide-backdrop centered hide-header-close hide-footer
      no-close-on-esc no-close-on-backdrop cancel-title>
      <div class="row">
        <div class="col">
          <img class='mx-auto d-block' :src="getImgUrl('pleasewait')" alt="logo">
        </div>
      </div>

      <br>

      <div class="row text-center">
        <div class="col">
          <h1>Your report is being prepared...</h1>
          <p>This may take some time, so please wait.</p>
        </div>
      </div>
    </b-modal>

    <!-- Error modal -->
    <b-modal v-model="isError" hide-backdrop centered ok-only>
      <template slot="modal-title">
        <h2 class="modal-title">Error!</h2>
      </template>

      <p>{{ error }}</p>
    </b-modal>

  </b-navbar>
</template>

<script lang="js">
import Vue from 'vue'
import { mapActions, mapGetters } from "vuex";

import auditService from "@/api/auditService.js";
import authService from "@/api/authService.js";
import { getImgUrl } from "@/utils/images";
import statusService from "@/api/statusService";
import { checkAllowedLLD } from "@/utils/LLDUtils.js"
import UCentric from "@/views/ucentric";
import { forceDownload } from "@/utils/downloadFile.js";

// These are the only services thats state we are interested in.
const InterestedServices = ['SvcUCOrchestrator', 'SvcUCServices'];

export default {
  data() {
    return {
      sso: {},
      serviceStatus: [],
      getImgUrl,
      isDownloading: false,
      isError: false,
      error: "",
      selectedLLD: ""
    };
  },
  async mounted() {
    try {
      let res = await authService.ssoDetails();
      this.sso = res.data;

      res = await statusService.getSystemStatus();
      if (res.data) {
        this.serviceStatus = res.data.ucServices.filter(service => InterestedServices.indexOf(service.name) !== -1);
      }

      const allowedLLDS = this.getLLDTypes.filter(lld => checkAllowedLLD(lld.id, this.getGroupProfile.SPALLD));
      this.selectedLLD = allowedLLDS.length > 0 ? allowedLLDS[0].id : 'Excel';
    } catch (e) {
      console.log("Error:", e);
    }
  },
  computed: {
    ...mapGetters([
      "getUsername",
      "getGroupProfile",
      "isLoggedIn",
      "isSPAAdmin",
      "isAuditAdmin",
      "getSSO",
      "getLLDTypes"
    ])
  },
  methods: {
    ...mapActions([
      "logout",
      "clearExternalAuth"
    ]),

    checkAllowedLLD,

    manageSites() {
      this.$router.push("/manage-sites")
    },

    home() {
      this.$router.push("/")
    },

    async logoutUser() {
      var ssoSession = Vue.$cookies.get('SSO_Session');
      if (ssoSession) {
        // Only keep the previous SSO type.
        Vue.$cookies.set('SSO_Session', { SSO: ssoSession.SSO });
      }

      // Fetch SSO data
      try {
        const sso = this.getSSO;

        this.logout();
        this.clearExternalAuth();

        // Redirect to Endpoint if Type is set
        if (sso.Type) {
          return window.location.href = sso.Endpoint;
        } else {
          return window.location.reload();
        }
      } catch (_) {
        return window.location.reload();
      }
    },

    async downloadSampleReport() {
      try {
        this.isDownloading = true;

        UCentric.migrationSettings.output.outputFormat = this.selectedLLD

        const response = await auditService.exportSampleAudit(JSON.stringify(UCentric.migrationSettings));

        // Extract the file name from disposition header
        let fn = response.headers["content-disposition"].split("filename=");
        if (fn.length > 0) {
          // Split further and remove double quotes
          fn = fn[1].replace(/['"]+/g, '');
        } else {
          fn = "ProvisioningTemplate.xlsx";
        }

        forceDownload(fn, response.data);
      } catch (e) {
        // Hide downloading modal and show error
        this.isDownloading = false;
        this.isError = true;
        this.error = "An error occurred downloading the report, please try again";
      } finally {
        this.isDownloading = false;
      }
    }
  }
};
</script>

<style>
.dropdown-menu {
  outline: none !important;
}

.dropdown-success a {
  color: green;
}

.dropdown-error a {
  color: red;
}
</style>