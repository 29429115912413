<template>
  <div>
    <!-- Loading is complete so show generic login if not using SSO -->
    <b-modal v-model="showModal" hide-backdrop centered hide-header-close hide-footer no-close-on-esc no-close-on-backdrop>
      <template slot="modal-header">
        <h2 class="modal-title">UCentric Login</h2>
        <span>{{ version }}</span>
      </template>

      <!-- Error -->
      <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>

      <!-- Login form -->
      <form @submit.prevent="submitLogin" autocomplete="on">
        <base-input
          type="text"
          id="inputUsername"
          inputClasses="boldInput"
          placeholder="Username"
          v-model="username"
          addon-left-icon="ni ni-badge"
        />

        <base-input
          type="password"
          id="inputPassword"
          inputClasses="boldInput"
          placeholder="Password"
          v-model="password"
          addon-left-icon="ni ni-key-25"
        />

        <b-button id="btnLogin" type="submit" block variant="primary">
          Login
        </b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { version } from "../../package.json";
import authService from "@/api/authService.js";

import { getImgUrl } from "@/utils/images.js";

export default {
  data() {
    return {
      username: "",
      password: "",

      showModal: true,
      error: "",

      version,
      getImgUrl
    };
  },
  methods: {
    ...mapActions([
      "login"
    ]),
    async submitLogin() {
      const password = this.encryptPassword(this.password);

      try {
        const response = await authService.login(this.username, password);
        this.login(response.data);
      } catch (e) {
        if (e.response.data && e.response.data.error) {
          this.error = e.response.data.error;
        }
      }
    },
    encryptPassword: function(password) {
      var encPassword = "";

      for (var i = 0; i < password.length; i++) {
        encPassword += String.fromCharCode(password.charCodeAt(i) ^ 255);
      }

      return encPassword = "&&" + btoa(encPassword) + "&&";
    }
  }
};
</script>

<style>
.boldInput {
  color:black !important;
  font-weight:bold  !important;
}
</style>
