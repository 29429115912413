<template>
  <b-modal v-model="showModal" size="lg" hide-backdrop centered hide-header-close hide-footer no-close-on-esc no-close-on-backdrop>
    <!-- ToS header -->
    <template slot="modal-title">
      <h2 class="modal-title">Terms of Use</h2>
    </template>

    <!-- Content -->
    <template>
	  <div v-if="loading">
		<div class="row">
        	<div class="col">
        		<img class='mx-auto d-block' :src="getImgUrl('pleasewait')" alt="logo">   
        	</div>
      	</div>

		<br>
	  </div>
      
	  <div v-if="!loading" class="border rounded p-2 m-2 overflow-auto" style="max-height: 70vh">
		  <p class="text-break" style="white-space: pre-wrap">{{ terms }}</p>
	  </div>
    </template>

    <p class="font-weight-bold text-center">I understand and accept the Terms of Use.</p>
    <b-button id="btnAcceptTerms" @click="acceptTerms" variant="primary" block>
      I Accept
    </b-button>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getImgUrl } from "@/utils/images";
import axios from "axios";

export default {
  data() {
	return {
		terms: "",
		loading: true,
		showModal: true,

		getImgUrl
	}
  },
  computed: {
	  ...mapGetters([
		  "getSSO"
	  ])
  },
  methods: {
	...mapActions([
		"acceptTerms"
	])
  },
  async mounted() {
	// Fetch terms from SSO and try parse as URL, otherwise use the Terms
	const sso = this.getSSO;

	try {
		new URL(sso.Terms);
		const response = await axios.get(sso.Terms);
		this.terms = response.data;
	} catch (_) {
		this.terms = sso.Terms;
	} finally {
		this.loading = false
	}
  }
};
</script>

<style scoped>
/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5e72e4;
  border-radius: 10px;
}
</style>