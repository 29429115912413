import apiClient from "./baseService";

export default {
	getPreviousJobList(amount) {
		return apiClient.get(`/amgetauditeventhistory?top=${amount}`);
	},

	downloadHLD(hld, siteId, scanDate) {
		return apiClient.get(`/ammergedoc?template=${hld}&amSiteId=${siteId}&amScanDate=${scanDate}`, {
			responseType: 'arraybuffer',
			timeout: 600000
		});
	},

	downloadLLD(siteId, options) {
		return apiClient.get(`/exportaudit?amSiteId=${siteId}&options=${options}`, {
			responseType: 'arraybuffer',
			timeout: 600000
		});
	},
	
	downloadProvisionResults(siteId) {
		return apiClient.get(`/api/jobs/${siteId}/file`, {
			responseType: 'arraybuffer',
			timeout: 600000
		})
	},

	downloadEventLog(scheduleId, siteId, timestamp, asCSV) {
		let queryString = `/ucGetOrchestrationStatus?`;

		if (scheduleId) {
			queryString += `&scheduleId=${scheduleId}`;
		}

		if (siteId) {
			queryString += `&amSiteId=${siteId}`;
		}

		if (timestamp) {
			queryString += `&sdate=${timestamp}`;
		}

		if (asCSV) {
			queryString += '&simpleCSV=1';
		}

		return apiClient.get(queryString, { responseType: 'blob' });
	},
}