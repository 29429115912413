<template>
	<div>
		<!-- Generic loading modal if validating SSO -->
		<b-modal id="loadingModal" v-model="isValidatingSSO" hide-backdrop centered hide-header-close hide-footer no-close-on-esc no-close-on-backdrop cancel-title>
			<div class="row">
        		<div class="col">
          			<img class='mx-auto d-block' :src="getImgUrl('pleasewait')" alt="logo">   
        		</div>
      		</div>

      		<br />

      		<div class="row">
        		<div class="col text-center">
          			<h3>Authenticating via SSO, please wait...</h3>
        		</div>
      		</div>
		</b-modal>

		<!-- Error modal -->
    	<b-modal v-model="showError" hide-backdrop centered hide-header-close hide-footer no-close-on-esc no-close-on-backdrop>
      		<template slot="modal-title">
        		<h2 class="modal-title">Error!</h2>
      		</template>

      		<p>{{ error }}</p>
    	</b-modal>
	</div>
</template>

<script>
import Vue from "vue";
import { getImgUrl } from "@/utils/images.js";
import { mapActions, mapGetters } from 'vuex';
import authService from "@/api/authService.js";

export default {
	name: 'SSO',
	computed: {
		...mapGetters([
			"getSSO"
		])
	},
	data() {
		return {
			isValidatingSSO: false,

			showError: false,
			error: "",

			// Functions
			getImgUrl
		}
	},
	methods: {
		...mapActions([
			"login"
		])
	},
	async mounted() {
		// Check and validate SSO session
		let ssoSession = Vue.$cookies.get('SSO_Session');

    	// If there is no SSO session but a SSO type, enforce the type
    	if (!ssoSession && this.getSSO.Type) {
      		ssoSession = {
        		SSO: this.getSSO.Type
      		}
    	}

		// Something should be present in the SSO session now, so handle it as necessary
		if (ssoSession) {
			this.isValidatingSSO = true;

			try {
				const res = await authService.validateSsoSession(ssoSession);
				if (res.data) {
					this.login({accessToken: res.data, refreshToken: null})
					this.$emit("validated")
				}
			} catch (e) {
				if (e.response.data.location) {
          			window.location.href = e.response.data.location;
          			return
        		} else {
					if (e.response && e.response.data) {
						this.showError = true;
						this.error = e.response.data.error;
						return;
					}

					this.showError = true;
					this.error = "An unknown error has occurred!";
					return;
				}
			} finally {
				this.isValidatingSSO = false;
			}
		}
	}
}
</script>