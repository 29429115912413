<template>
  <div id="app">
    <Banner v-if="bannerText" :text="bannerText" />
    <Navbar />
    <router-view @hide-footer="showFooter = false" :key="$route.fullPath" />
    <Footer v-if="showFooter" />
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import authService from "@/api/authService.js";

export default {
  components: {
    Banner,
    Navbar,
    Footer
  },

  data() {
    return {
      bannerText: null,
      showFooter: true
    }
  },

  async mounted() {
    // Check for banner on initial load
    try {
      const res = await authService.bannerText();
      this.bannerText = res.data.message;
    }
    catch (e) {
      console.log("An error occurred fetching banner text.");
    }

    // Check for new banner every 5 minutes
    setInterval(async () => {
      try {
        const res = await authService.bannerText();
        this.bannerText = res.data.message;
      }
      catch (e) {
        console.log("An error occurred fetching banner text.");
      }
    }, 5 * 60000);
  },
}
</script>