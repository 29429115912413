<template>
  <footer class="footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          &copy; {{year}} <a href="https://www.mit-tech.co.uk" class="font-weight-bold ml-1" target="_blank">MIT Dynamic</a> - {{ version }}
        </div>
      </div>

      <div class="col-lg-6">
        <ul class="nav nav-footer justify-content-center justify-content-lg-end">
          <li class="nav-item">
            <a href="https://www.mit-tech.co.uk" class="nav-link" target="_blank"> <img src="@/assets/logos/UC_Banner_Powered.png" width="200px"></a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { version } from "../../package.json";

export default {
  data() {
    return {
      year: new Date().getFullYear(),
      version
    };
  }
};
</script>