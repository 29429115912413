<template>
    <div class="p-3 bg-primary text-center text-white">
        <span class="text-break" style="white-space: pre-wrap">{{ text }}</span>
    </div>
</template>

<script>
export default {
    name: "Banner",
    props: {
        text: String
    }
}
</script>