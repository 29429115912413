import axios from "axios";

export default {
  async exchangeToken(settings, code) {
    let params = new URLSearchParams({
      grant_type: 'authorization_code',
      code: code,
      redirect_uri: settings.redirect_uri,
      client_id: settings.client_id,
    });

    if (settings.client_secret) {
      params.append('client_secret', settings.client_secret);
    }

    // If proxyAuth is set then we need to push to the UC backend server to a) bypass CORS or b) deal with Zoom's request where tokens can't be exposed (This will return an encrypted token which needs to be decrypted by Auditing/Provisioning)
    let url = settings.oauthEndpoints.token_endpoint;
    if (settings.oauthEndpoints.proxyAuth) {
      url = `${window.location.protocol}//${window.location.hostname}:8809/api/token/exchange/${settings.oauthEndpoints.token_endpoint}`
    }
   
    return (await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }))?.data;
  },

  async getUserProfile(settings, token) {
    try {
      return (await axios.get(settings.oauthEndpoints.userinfo_endpoint, {
        headers: { Authorization: `Bearer ${token}` }
      }))?.data || {};
    }
    catch (error) {
      return {};
    }
  }
}