<template>
  <tab-pane title="Auditing" id="tabAuditing">
    <span slot="title">
      <i class="ni ni-check-bold"></i>
      &nbsp;Auditing
    </span>

    <div class="row justify-content-md-center">
      <div v-if="!showLiveAudit.visible" class="col-xl-12">
        <card header-classes="bg-transparent">
          <!-- Error alert -->
          <div v-if="error.show && !invalidBackupFile" class="alert alert-danger">
            <p>{{ error.message }}</p>
          </div>

          <!-- Error alert for invalid backup types -->
          <div v-if="error.show && invalidBackupFile" class="alert alert-danger">
            <p>{{ error.message }} For more information on supported file types, please select one of the supported
              vendors below.</p>
            <b-dropdown text="Select vendor..." class="customer-dropdown w-100" id="dropSelectVendor">
              <template #button-content>
                <span v-if="systemType.type">
                  <img :src="getImgUrl(systemType.icon)" /> {{ systemType.desc }}
                </span>
                <span v-else>Select vendor...</span>
              </template>

              <b-dropdown-item v-for="system in supportedSystems" :key="system.type + system.icon"
                @click="updateType(system.type)">
                <img :src="getImgUrl(system.icon)" /> {{ system.desc }}
              </b-dropdown-item>
            </b-dropdown>

            <!-- Help text and supported file types -->
            <div class="pt-4" v-if="systemType.desc && systemType.supportedFileTypes.length !== 0">
              <p>
                <span class="font-weight-bold">
                  {{ systemType.supportedFileTypes.join(', ') }}
                </span>

                files are backups created within the <span class="font-weight-bold">{{ systemType.desc }}</span>
                management platform - please refer to your maintainer for details on how to obtain this form of backup.
              </p>
            </div>
          </div>

          <!-- Error alert for encrypted backups -->
          <div v-if="isEncrypted" class="alert alert-danger">
            <p>You must provide an unencrypted backup in order to continue.</p>
          </div>

          <!-- Description box for system type -->
          <div v-if="systemType.desc && !invalidBackupFile && !fileUploadError" class="alert alert-primary">
            <p>Detected: {{ systemType.desc }}</p>
          </div>

          <!-- Dropzone -->
          <Vue-Dropzone v-show="!hideDropzone" id="dropzone" ref="dropzone" :options="dropzoneOptions"
            @vdropzone-complete="fileUploadComplete" @vdropzone-sending="attachJWT" />

          <div v-if="!hideDropzone && (getSSO && !getSSO.HideDirectConnect)">
            <p class="text-center divider-label">Or select a direct connection</p>
            <AuditCredentialsForm @fire-direct-audit="executeDirectAudit" />
          </div>

          <!-- Reset/clear audit -->
          <button id="btnResetAudit" v-if="!hideDropzone && fileUploadError" type="button"
            class="btn btn-link mt-4 mx-auto d-block" @click="clearAudit">Start over and run another audit?</button>

          <!-- Site configuration -->
          <div v-if="hideDropzone && showSiteConfig">
            <div class="row justify-content-md-center pb-md-0 pb-4">
              <!-- New site or existing site -->
              <div class="col-md">
                <base-radio name="new" class="mb-3" v-model="radioSite" id="radioNewSite">
                  <h3>New Customer</h3>
                </base-radio>

                <base-input
                  ref="radiosite"
                  v-model="newSiteName"
                  :disabled="radioSite != 'new'"
                  addon-left-icon="ni ni-pin-3"
                  placeholder="Enter customer name"
                  id="inputNewSiteName"
                  @keyup.enter="schedulePlan"
                  maxlength="50"
                  :style="{
                    'border': (!isCustomerNameValid) ? '1px solid red' : '',
                    'border-radius': '5px'
                  }"
                />

                <div v-if="!isCustomerNameValid" class="mt-n4 mb-3 ml-1" style="color: red;">
                  Invalid character in customer name, only alpha-numeric characters and spaces are allowed
                </div>
              </div>

              <div class="col-md">
                <base-radio name="existing" class="mb-3" v-model="radioSite" id="radioExistingSite">
                  <h3>Existing Customer</h3>
                </base-radio>

                <b-dropdown id="dropSelectedCustomer" menu-class="w-100" class="customer-dropdown w-100" block
                  :text="truncate(selectedCustomer) || 'Select from customer list'" :title="selectedCustomer"
                  :disabled="radioSite != 'existing'" @hidden="clearSearch">
                  <form @submit.prevent class="px-2 py-2">
                    <input type="search" v-model="search" class="form-control form-control-sm"
                      placeholder="Search for a customer" autofocus>
                  </form>

                  <b-dropdown-item @click="updateCustomer(customer)" v-for="customer in resultQuery" :key=customer.id
                    :title="formatCustomerNode(customer.nodeId, customer.siteName)">
                    {{ truncate(formatCustomerNode(search ? "" : customer.nodeId, customer.siteName)) }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <!-- SoW Number -->
            <div v-if="profileData.SPASOW && radioSite == 'new'" class="row">
              <div class="col">
                <h3>{{ profileData.SPASOWLabel }}</h3>

                <base-input id="inputSOW" v-model="sowId" :placeholder="`Enter ${profileData.SPASOWLabel}`"
                  @keyup.enter="schedulePlan"></base-input>
              </div>
            </div>

            <!-- Workflow Order Number - mandatory for users with Mitel profile option -->
            <div v-if="profileData.SPAClientId && radioSite == 'new'" class="row">
              <div class="col">
                <h3>{{ profileData.SPAClientIdLabel }}</h3>

                <base-input id="inputOpportunityId" v-model="opportunityId"
                  :placeholder="`Enter ${profileData.SPAClientIdLabel}`" @keyup.enter="schedulePlan"></base-input>
              </div>
            </div>

            <!-- Phase Id -->
            <div v-if="profileData.SPAPhase && radioSite == 'new'" class="row">
              <div class="col">
                <h3>{{ profileData.SPAPhaseLabel }}</h3>

                <base-input id="inputPhase" v-model="phaseId" :placeholder="`Enter ${profileData.SPAPhaseLabel}`"
                  @keyup.enter="schedulePlan"></base-input>
              </div>
            </div>

            <PartnerType v-if="radioSite == 'new'" ref="PartnerType" :profileData="profileData"
              @updated="sp => selectedPartner = sp" />

            <div class="row">
              <div class="col mb-3">
                <h3>Select ingest platform type</h3>
                <h5>(Note: Only available platforms are enabled)</h5>

                <base-dropdown>
                  <base-button slot="title" id="dropPlatformType" type="secondary" class="dropdown-toggle">
                    <img :src="getImgUrl(systemType.icon)" />
                    {{ systemType.desc }}
                  </base-button>

                  <!-- Get @click event working -->
                  <li v-for="s in supportedSystems" :key="s.type" id="dropItemPlatformType">
                    <a v-if="s.visible == true" class="dropdown-item" @click="updateType(s.type)">
                      <img :src="getImgUrl(s.icon)" /> {{ s.desc }}
                    </a>
                  </li>
                </base-dropdown>
              </div>

              <div class="col mb-3" v-if="getOverriddenProfiles && getOverriddenProfiles.length > 0">
                <h3>Select target platform type</h3>
                <h5>(Note: Only available platforms are enabled)</h5>

                <base-dropdown>
                  <base-button slot="title" id="dropProfileOverrideType" type="secondary" class="dropdown-toggle">
                    {{ overiddenProfileType.profileName }}
                  </base-button>

                  <!-- Get @click event working -->
                  <li v-for="profile in getOverriddenProfiles" :key="profile.profileUid" id="dropItemOverideType">
                    <a class="dropdown-item" @click="updateTypeOverideType(profile.profileUid)">
                      {{ profile.profileName }}
                    </a>
                  </li>
                </base-dropdown>
              </div>
            </div>

            <div class="alert alert-light" v-b-toggle.collapse-1>
              <i class="ni ni-settings-gear-65 mr-2"></i>
              <span>Advanced options</span>
            </div>
            <b-collapse id="collapse-1" class="mt-2 mb-2">
              <b-card>
                <b-row class="pb-4">
                  <b-col cols="4">
                    <b-button id="btnFileUpload" variant="primary" title="File upload"
                      @click="openAdditionalFileTypeModal">
                      <i class="fas fa-upload"></i>
                    </b-button>
                  </b-col>
                  <b-col cols="4">
                    <p class="text-center">Additional File Upload</p>
                  </b-col>
                </b-row>

                <AdditionalFilesTable :files="additionalFiles" @removeFile="removeAdditionalFile" />
              </b-card>

              <!-- Decoder Switches -->
              <b-card class="mt-2" v-if="systemType.extraSwitches.length > 0">
                <p class="text-center">Additional Decoder Options</p>
                <b-row class="pb-4">
                  <select class="custom-select" multiple size="5" v-model="selectedSwitches">
                    <option v-for="option in systemType.extraSwitches" :key="option.description"
                      :value="` --${option.switch}`">{{ option.description }}</option>
                  </select>

                  <p @click="selectedSwitches = []" style="cursor: pointer; user-select: none;">Reset Selection</p>
                </b-row>
              </b-card>
            </b-collapse>

            <!-- Email mode - document selection -->
            <div v-if="!isDirectMode" class="row">
              <div class="col mb-3">
                <h3>Select documents</h3>
                <base-checkbox :inline="true" class="mb-3" checked disabled>
                  <b>HLD (Word Document Summary) (Always required)</b>
                </base-checkbox>

                <base-checkbox v-if="(getGroupProfile.SPAHideLLD || '0') == '0' && !isDirectMode" :inline="true"
                  class="mb-3" checked>
                  <b>LLD (Bulk Migration Plan)</b>
                </base-checkbox>
              </div>
            </div>

            <!-- Email mode - recipient email address -->
            <div v-if="!isDirectMode" class="row">
              <div class="col mb-3">
                <base-input v-model="emailAddress" placeholder="Enter email address"
                  @input="emailValid = validateEmail(emailAddress)"
                  label="Email address (this is where reports will be sent to)" @keyup.enter="schedulePlan" required />
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <base-button @click="schedulePlan"
                  :disabled="!(emailValid || isDirectMode) || disableCreateMigrationPlanBtn || !isCustomerNameValid" type="primary" id="btnSend"
                  icon="ni ni-send">
                  Create migration plan
                </base-button>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <!-- Reset/clear audit -->
                <button id="btnResetAudit" type="button" class="btn btn-link mt-4 mx-auto d-block"
                  @click="clearAudit">Start over and run another audit?</button>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- Show audit progress (maybe move into its own component at some point) -->
      <div v-if="showLiveAudit.visible && !downloadVisible && !showDone" class="col-12">
        <card header-classes="bg-transparent">
          <!-- Showcase the events data and show download reports when completed -->
          <Events action="Audit" v-if="showEvents" :eventHistory="eventHistory" :jobId="amJobId" :siteId="amSiteId"
            :timestamp="amTimestamp" @completed="prepareDownloads" @retry="clearAudit" />
        </card>
      </div>

      <!-- Show alert saying that an audit has been scheduled -->
      <div v-if="showDone" class="col-12">
        <card header-classes="bg-transparent" class="text-center">
          <h2>Migration in progress...</h2>
          <p>Your migration plan is in progress, and you should receive an email with the requested reports within 1 hour.
          </p>
          <!-- <p>Audit will be stored as XXX - ID: XX</p> -->

          <!-- Reset/clear audit -->
          <button id="btnResetAudit" type="button" class="btn btn-link mt-4 mx-auto d-block" @click="clearAudit">Run
            another audit?</button>
        </card>
      </div>

      <!-- Show download options for HLD and LLD  -->
      <DownloadReports
        v-if="showLiveAudit.visible && downloadVisible"
        :files="files"
        action="Auditing"
        :scheduleId="amJobId"
        :siteId="amSiteId"
        :groupProfile="overiddenProfileType.profileJSON ? JSON.parse(overiddenProfileType.profileJSON) : getGroupProfile"
        :overrideFileInfo="overrideFileInfo"
      >
        <template v-slot:action>
          <!-- Reset/clear audit -->
          <button id="btnResetAudit" type="button" class="btn btn-link mt-4 mx-auto d-block" @click="clearAudit">Run
            another audit?</button>
        </template>
      </DownloadReports>

      <!-- Modal to change additional files types -->
      <AdditionalFiles v-if="showAdditionalFileTypeModal" :fileTypes="additionalFileTypes"
        @close="showAdditionalFileTypeModal = !showAdditionalFileTypeModal" @file="addAdditionalFile" />
    </div>
  </tab-pane>
</template>

<script>
// API Services
import authService from "@/api/authService";
import auditService from "@/api/auditService";

import UCentric from "@/views/ucentric";
import { getImgUrl } from "@/utils/images";
import { validateEmail } from "@/utils/email";
import { version } from "../../package.json";

// Components
import vue2Dropzone from "vue2-dropzone";
import Events from "@/components/Events.vue";
import DownloadReports from "@/components/DownloadReports.vue";
import AdditionalFiles from "@/components/AdditionalFiles.vue";
import AdditionalFilesTable from "@/components/AdditionalFilesTable.vue";
import PartnerType from "@/components/PartnerType.vue";
import AuditCredentialsForm from "@/components/AuditCredentialsForm.vue"
import { mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";

const AuditExe = "Audit.exe";

export default {
  components: {
    VueDropzone: vue2Dropzone,
    Events,
    DownloadReports,
    AdditionalFiles,
    AdditionalFilesTable,
    PartnerType,
    AuditCredentialsForm
  },

  data() {
    return {
      eventHistory: [],
      fileName: "",
      systemType: {},
      overiddenProfileType: { profileName: 'Select a target' },
      supportedSystems: [],
      profileData: {},
      selectedPartner: "",

      customerList: [],
      selectedCustomer: "",
      search: "",

      hideDropzone: false,
      showSiteConfig: false,
      radioSite: "new",
      newSiteName: "",
      opportunityId: "",
      sowId: "",
      phaseId: "",
      selectedSwitches: [],

      amSiteId: 0,
      amTimestamp: 0,
      amScanDate: "",
      amJobId: 0,
      showEvents: false,

      // Email mode
      emailAddress: "",
      emailValid: false,
      showDone: false,

      dropzoneOptions: {
        url: `${window.location.protocol}//${document.location.hostname}:8809/tempfile`,
        params: {
          random: 1
        },
        thumbnailWidth: 150,
        maxFilesize: 20000,
        maxFiles: 1, // Handle exceeds
        timeout: 600000, // Override default of 30 seconds to 10 minutes
      },

      // Additional files to merge
      additionalFiles: [],
      showAdditionalFileTypeModal: false,
      additionalFileTypes: [
        {
          name: "Active Directory",
          type: "AD",
          disabled: false
        },
        {
          name: "DDI",
          type: "ddi",
          disabled: false
        },
        {
          name: "Devices",
          type: "devices",
          diabled: false
        },
        {
          name: "Network",
          type: "network",
          disabled: false
        },
        {
          name: "Recorder",
          type: "recorder",
          disabled: false
        },
        {
          name: "Voicemail",
          type: "voicemail",
          disabled: false
        },
        {
          name:"Device state",
          type: "devicestate",
          disabled: false
        }
      ],

      invalidBackupFile: false,
      isEncrypted: false,
      fileUploadError: false,

      showLiveAudit: {
        visible: false,
      },

      downloadVisible: false,
      files: [],

      // Details used for error prompt
      error: {
        show: false,
        message: "",
      },

      disableCreateMigrationPlanBtn: false,

      getImgUrl,
      validateEmail,
      version,
    };
  },

  computed: {
    ...mapGetters([
      "getToken",
      "getDefaultNode",
      "getGroupProfile",
      "getHandsets",
      "isDirectMode",
      "getAllowedPABXTypes",
      "getPABXTypes",
      "getSSO",
      "getOverriddenProfiles",
      "isAuditAuth",
      "getExternalAuthToken",
      "getSAPReferences",
      "getAppInfo"
    ]),

    resultQuery() {
      // Only want to search by siteName
      if (this.search) {
        return this.customerList.filter((item) => {
          return this.search.toLowerCase().split(' ').every(v => this.formatCustomerNode("", item.siteName).toLowerCase().includes(v))
        })
      } else {
        return this.customerList;
      }
    },

    isCustomerNameValid() {
      // Define a regular expression for alphanumeric characters
      const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;

      // Check if the newSiteName contains any invalid characters
      if (!alphanumericRegex.test(this.newSiteName)) {
        return false;
      }

      return true;
    },

    overrideFileInfo() {
      if(!this.getAppInfo?.global?.HLDFilenameFormat && !this.getAppInfo?.global?.LLDFilenameFormat) {
        return false;
      }

      const [resultAfter] = this.selectedCustomer.match(/\\([^\\]+)\\([^\\]+)$/) || '';

      return {
        customerName: this.radioSite == 'new' ? this.newSiteName : resultAfter,
        scanDate: this.amScanDate,
        currentDate: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '').replace(/:/g, '-'),
      };
    }
  },

  filters: {
    parseDate: function (unixDate) {
      return DateTime.fromSeconds(unixDate).toFormat("D TT");
    },
  },

  beforeUpdate() {
    if (this.getOverriddenProfiles && this.getOverriddenProfiles.length > 1 && !this.overiddenProfileType.profileUid) {
      this.overiddenProfileType = { profileName: 'Select a target' };
    } else if (this.getOverriddenProfiles && this.getOverriddenProfiles.length === 1) {
      this.overiddenProfileType = this.getOverriddenProfiles[0];
    }
  },

  async mounted() {
    let isLoaded = false;
    let attemptsToLoad = 0;

    do {
      isLoaded = await this.InitAuditingComponent();
      attemptsToLoad++;
    } while (!isLoaded && attemptsToLoad < 3);

    // TODO: Remove once find root cause of lld list returning 401 on mitel production server
    // Refreshes page once, if fails, will refresh again and take it to login page
    let initAuditing = Number(localStorage.getItem('initAuditing')) ?? localStorage.setItem('initAuditing', 0);

    if(!isLoaded) {
      if (initAuditing < 1) {
        localStorage.setItem('initAuditing', initAuditing + 1);
      }
      else {
        localStorage.clear();
        this.clearAllCookies();
      }
      
      location.reload(true);
    } 
  },

  methods: {
    ...mapActions([
      "overideProfiles",
      "clearExternalAuth"
    ]),

    async InitAuditingComponent() {
      try {
        await this.$store.dispatch("initializePABXTypes") || (() => { throw new Error("PABX types not loaded"); })();
        
        await this.$store.dispatch("initializeLLDTypes") || (() => { throw new Error("LLD types not loaded"); })();

        this.profileData = this.getGroupProfile;
  
        // Load profiles to override assigned profiles with
        const targetProfilesResponse = await authService.getTargetProfiles();
        if (targetProfilesResponse.data) {
          this.overideProfiles(targetProfilesResponse.data.rows.filter(p => this.getGroupProfile.SPADYNAMIC && this.getGroupProfile.SPADYNAMIC.includes(p.profileUid.toString())));
        }
  
        if (this.isAuditAuth) {
          let selectedPbx = this.getPABXTypes.find(pbx => pbx.type.toUpperCase() == this.getExternalAuthToken.identifier);
          if (selectedPbx) {
            this.executeDirectAudit(selectedPbx);
          }
        }

        return true;
      }
      catch(error) {
        return false;
      }
    },

    clearAllCookies() {
      let cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i];
          let eqPos = cookie.indexOf("=");
          let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      }
    },

    // Attach JWT to Dropzone when a file is uploaded
    attachJWT(_file, xhr) {
      xhr.setRequestHeader('Authorization', `Bearer ${this.getToken}`);
    },

    // Show modal allowing user to change additional file types
    openAdditionalFileTypeModal() {
      this.showAdditionalFileTypeModal = !this.showAdditionalFileTypeModal;
    },

    // Add additional file to list and disable that file type for future uploads
    addAdditionalFile(event) {
      this.additionalFiles.push(event);

      const fileTypeIndex = this.additionalFileTypes.findIndex(f => f.type === event.fileType);
      if (fileTypeIndex > -1) {
        this.additionalFileTypes[fileTypeIndex].disabled = true;
      }
    },

    // Remove an additional file from the list and re-enable the uploaded type
    removeAdditionalFile(file) {
      // Re-enable the uploaded type
      const fileTypeIndex = this.additionalFileTypes.findIndex(f => f.type === file.fileType);
      if (fileTypeIndex > -1) {
        this.additionalFileTypes[fileTypeIndex].disabled = false;
      }

      // Remove the uploaded file from our array
      const fileArray = this.additionalFiles.filter((f) => f.newFileName !== file.newFileName);
      if (fileArray) {
        this.additionalFiles = fileArray;
      }
    },

    // Handle file upload completion
    async fileUploadComplete(file) {
      // Cleanup previous error
      this.error.show = false;
      this.error.message = "";
      this.invalidBackupFile = false;
      this.isEncrypted = false;

      const res = JSON.parse(file.xhr.response);
      this.fileName = res.newName;

      try {
        // Detect vendor type from backup file
        const response = await auditService.detectVendorBackupType(this.fileName);

        // Filter out allowed backup types
        this.getPABXTypes.forEach((system, i) => {
          this.getPABXTypes[i].visible = false;

          // Check if system falls under allowed PABX types and add it to our supported systems
          if (this.getAllowedPABXTypes.some(s => system.type.toLowerCase().includes(s))) {
            this.supportedSystems.push(system);
          }

          // If allowed PABX types is empty, assume that all systems are allowed
          if (this.getAllowedPABXTypes.length === 0) {
            this.supportedSystems.push(system);
          }
        });


        // If there is no response, then assume that a type cannot be found
        if (response.data.detectedTypes.length === 0) {
          this.error.message = `Backup type for '${file.name}' cannot be detected. Please try another, or refer to your account manager.`;
          this.error.show = true;
          this.invalidBackupFile = true;

          // Clear uploaded files
          this.$refs.dropzone.removeAllFiles();

          return;
        }

        if (response.data.detectedTypes.length === 1 && response.data.detectedTypes[0] === 'ENCRYPTED') {
          this.isEncrypted = true;

          // Clear uploaded files
          this.$refs.dropzone.removeAllFiles();

          return;
        }

        if (response.data.detectedTypes) {
          const allowedPABXTypes = this.getAllowedPABXTypes.map(type => type.toLowerCase()); // Convert types to lower case for consistency
          for (let i = 0; i < response.data.detectedTypes.length; i++) {
            const type = response.data.detectedTypes[i];

            // If types are present, but the detected type can't be found, don't allow an audit to take place.
            if (allowedPABXTypes.length > 0 && !allowedPABXTypes.includes(type.toLowerCase())) {
              this.error.message = `You are not authorized to audit a '${type}' backup. Please contact your account manager.`
              this.error.show = true;

              // Clear uploaded files
              this.$refs.dropzone.removeAllFiles();
              return;
            }
          }

          // Otherwise we can find the matching type from the supported systems list
          response.data.detectedTypes.forEach((type) => {
            const system = this.getPABXTypes.find(s => s.type.toUpperCase() === type.toUpperCase() || s.databaseType.toUpperCase() === type.toUpperCase())
            if (system) {
              system.visible = true;
              this.systemType = system;
            }
          });

          if (!this.systemType.desc) {
            this.error.message = "Could not find detected type in list of vendors, please try again!"
            this.error.show = true;

            // Clear uploaded files
            this.$refs.dropzone.removeAllFiles();
            return;
          }

          const customerList = await this.getCustomerList();
          this.customerList = customerList;

          this.hideDropzone = true;
          this.showSiteConfig = true;
        }
      } catch (e) {
        // Check if unauthorized and clear uploaded data
        if (e.response && e.response.status === 401) {
          this.$refs.dropzone.removeAllFiles();
          this.clearAudit();
          return;
        }

        // Populate error modal and show error message if possible
        this.fileUploadError = true;
        this.error.show = true;
        this.error.message = e.response.data && e.response.data.description ? e.response.data.description : "This backup cannot be processed, please contact support.";
        return;
      }
    },

    // Get customer list (but this looks like a site list??)
    async getCustomerList() {
      // Cleanup error alert
      this.error.show = false;
      this.error.message = "";

      let customerList = [];

      try {
        const response = await auditService.getCustomerList(
          this.getDefaultNode,
          this.systemType.databaseType
        );

        customerList = response.data.rows;
      } catch (e) {
        // Populate error alert with details
        this.error.show = true;
        this.error.message = e;
      }

      return customerList;
    },

    // Update the selected customer in dropdown
    updateCustomer(customer) {
      if (customer != -1) {
        this.selectedCustomer = this.formatCustomerNode(
          customer["nodeId"],
          customer["siteName"]
        );
        this.amSiteId = customer.siteId;
      } else {
        this.selectedCustomer = "";
      }

      // Change focus to be on the submit button
      document.getElementById("btnSend").focus();
    },

    formatCustomerNode(nodeId, siteName) {
      if (nodeId.endsWith("\\")) {
        return `${nodeId}${siteName}`;
      }

      return `${nodeId}\\${siteName}`;
    },

    updateType(type) {
      var system = this.getPABXTypes.find((s) => s.type.toUpperCase() === type.toUpperCase());
      this.systemType = system;
    },

    updateTypeOverideType(id) {
      this.overiddenProfileType = this.getOverriddenProfiles.find(p => p.profileUid == id);
    },

    // Schedule an audit
    async schedulePlan() {
      // Reset error alert
      this.error.show = false;
      this.error.message = "";

      if (
        (this.newSiteName == "" && this.radioSite == "new") ||
        (!this.selectedCustomer && this.radioSite == "existing")
      ) {
        this.error.message = "You must select a site from the list, or select 'new site' and add a name";
        this.error.show = true;

        return;
      }

      // Check that an Workflow number is present and valid on new sites
      if (this.profileData.SPAClientId && this.radioSite == 'new') {
        // Validate the workflow number
        if (this.profileData.SPAClientIdRegex) {
          const reg = new RegExp(this.profileData.SPAClientIdRegex);
          if (!reg.test(this.opportunityId)) {
            this.error.message = `Please enter a valid ${this.profileData.SPAClientIdLabel} and try again!`;
            this.error.show = true;

            return;
          }
        }
      }

      // Check that an SOW number is present and valid on new sites
      if (this.profileData.SPASOW && this.radioSite == 'new') {
        // Validate the SOW number
        if (this.profileData.SPASOWRegex) {
          const reg = new RegExp(this.profileData.SPASOWRegex);
          if (!reg.test(this.sowId)) {
            this.error.message = `Please enter a valid ${this.profileData.SPASOWLabel} and try again!`;
            this.error.show = true;

            return;
          }
        }
      }

      // Check that an Phase Id is present and valid on new sites
      if (this.profileData.SPAPhase && this.radioSite == 'new') {
        // Validate the SOW number
        if (this.profileData.SPAPhaseRegex) {
          const reg = new RegExp(this.profileData.SPAPhaseRegex);
          if (!reg.test(this.phaseId)) {
            this.error.message = `Please enter a valid ${this.profileData.SPAPhaseLabel} and try again!`;
            this.error.show = true;

            return;
          }
        }
      }

      if (this.getOverriddenProfiles && this.getOverriddenProfiles.length > 0 && !this.overiddenProfileType.profileUid) {
        this.error.message = "Please select a target platform type.";
        this.error.show = true;

        return;
      }

      if (this.radioSite == 'new' && this.$refs.PartnerType.getPartnerTypes().length > 0 && this.selectedPartner == '') {
        this.error.message = "Please select a partner type.";
        this.error.show = true;

        return;
      }

      // Show validation error if email isn't correct
      if (!this.isDirectMode && !this.emailValid) {
        this.error.message = "Email address is not valid!";
        this.error.show = true;

        return;
      }

      this.disableCreateMigrationPlanBtn = true;

      const ip = this.systemType?.fields?.find(st => st.fieldName === 'ip')?.value || this.systemType?.settings?.url || '';
      const port = this.systemType?.fields?.find(st => st.fieldName === 'port')?.value || '';
      const username = this.systemType?.fields?.find(st => st.fieldName === 'username')?.value || '';
      const password = this.systemType?.fields?.find(st => st.fieldName === 'password')?.value || this.getExternalAuthToken?.access_token || '';

      try {
        let response;
        // Handle new site creation first
        if (this.radioSite == "new") {

          response = await auditService.addVoiceAuditSite(
            this.newSiteName,
            this.getDefaultNode,
            this.systemType.databaseType.toUpperCase(),
            this.opportunityId,
            this.sowId,
            this.phaseId,
            this.selectedPartner,
            ip,
            port,
            username,
            password,
            this.isAuditAuth ? 10 : 1,
            this.getSAPReferences?.primary || '',
            this.getSAPReferences?.additional || ''
          );
        } else {

          var existingSite = this.customerList.find(cl => cl.siteId == this.amSiteId);
          if (existingSite == null) {
            this.error.show = true;
            this.error.message = 'Failed to find existing customer.';

            this.disableCreateMigrationPlanBtn = false;

            return;
          }

          response = await auditService.updateVoiceAuditSite(
            existingSite,
            ip,
            port,
            username,
            password,
          );
        }

        this.amSiteId = response.data.siteId;
        this.amTimestamp = response.data.timestamp;
      } catch (e) {
        // Populate error alert with details
        this.error.show = true;
        this.error.message = e;

        this.disableCreateMigrationPlanBtn = false;

        return;
      }

      try {
        // Trigger voice audit schedule
        const response = await auditService.addVoiceAuditSchedule(
          this.amSiteId,
          AuditExe,
          this.systemType.type,
          this.fileName,
          this.version,
          UCentric.migrationSettings.outputFormat,
          this.getGroupProfile.SPAHLD,
          this.isDirectMode,
          this.emailAddress,
          this.getGroupProfile.emailTemplate,
          this.selectedSwitches
        );

        this.amJobId = response.data.scheduleId;
        this.amTimestamp = response.data.timestamp;
        this.amScanDate = response.data.scandate;

        this.showLiveAudit.visible = true;
        this.showEvents = true;

        if (!this.isDirectMode) {
          this.showDone = true;
        }

        this.clearExternalAuth();
      } catch (e) {
        console.log("Error adding voice audit schedule:", e);
      } finally {
        this.disableCreateMigrationPlanBtn = false;
      }
    },

    // Truncate a string if it exceeds a certain length
    truncate(str) {
      const MAX_LENGTH = 60;

      if (str.length > MAX_LENGTH) {
        return str.substring(0, MAX_LENGTH) + "...";
      } else {
        return str;
      }
    },

    // When customer dropdown is hidden, clear search
    clearSearch() {
      this.search = "";
    },

    // Prepare downloads and toggle them to be visible
    prepareDownloads() {
      this.downloadVisible = true;

      let profileData = this.overiddenProfileType.profileJSON ? JSON.parse(this.overiddenProfileType.profileJSON) : this.getGroupProfile;

      // Depending on the 'type', some additional parameters may need to be provided
      // HLD
      this.files.push({
        filename: "AuditSummary.docx",
        document: "High Level Design (.DOCX)",
        parameters: {
          docTemplate: this.getHLDTemplateType(profileData),
          siteId: this.amSiteId,
          scanDate: this.amScanDate,
          version: this.version,
          fallbackHld: profileData.SPAHLD
        },
        type: "hld",
        docType: "word",
      });

      // LLD
      const migrationSettings = UCentric.migrationSettings;
      migrationSettings.source.scans = [
        {
          siteId: this.amSiteId,
          scanDate: this.amScanDate
        },
      ];

      // If we have additional files, add them to the migration settings too
      if (this.additionalFiles && this.additionalFiles.length > 0) {
        this.additionalFiles.forEach(file => {
          migrationSettings.source[file.fileType].filename = file.newFileName;
        })
      }

      migrationSettings.checks.checkHandsets = this.getHandsets || "";
      migrationSettings.output.outputFormat = this.getGroupProfile.SPALLD || "Excel";
      migrationSettings.spa = this.version;
 
      this.files.push({
        filename: "ProvisioningTemplate.xlsx",
        document: "Low Level Design (.XLSX)",
        parameters: {
          siteId: this.amSiteId,
          settings: migrationSettings
        },
        type: "lld",
        docType: "excel"
      });
    },

    clearAudit() {
      // Clear field values
      if (this.systemType.fields) {
        this.systemType.fields.forEach(f => delete f.value);
      }

      Object.assign(this.$data, this.$options.data());
      if (this.$refs.dropzone) {
        this.$refs.dropzone.removeAllFiles();
      }

      // Clean-up migration settings in UCentric file
      this.additionalFileTypes.forEach(file => {
        UCentric.migrationSettings.source[file.type].filename = "";
      })

      this.profileData = this.getGroupProfile;

      this.clearExternalAuth();
    },

    getHLDTemplateType(groupProfile) {
      if (groupProfile.SPAHLD2 == 1) {
        return "*";
      } else if (groupProfile.SPAHLD3 == 1) {
        return "^";
      }
      return groupProfile.SPAHLD || "_Default_Template.docx";
    },
    
    async executeDirectAudit(pbx) {
      this.supportedSystems.push(pbx)
      this.systemType = pbx;

      const customerList = await this.getCustomerList();
      this.customerList = customerList;

      this.hideDropzone = true;
      this.showSiteConfig = true;
    }
  },

  watch: {
    overiddenProfileType(newProfile) {
      if (newProfile.profileJSON) {
        this.profileData = JSON.parse(newProfile.profileJSON);
      }
    }
  }
};
</script>

<style>
.dropdown .dropdown-toggle {
  cursor: pointer;
}

.customer-dropdown>.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-menu::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #dddddd;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background: #5e72e4;
  border-radius: 10px;
}

.divider-label {
  font-weight: bold;
  margin: 1rem;
  user-select: none;
}
</style>