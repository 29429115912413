import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app.js";
import auth from "./modules/auth.js";
import vendors from "./modules/vendors.js";
import externalAuth from "./modules/externalAuth.js";

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		app,
		auth,
		vendors,
		externalAuth
	}
});

export default store;