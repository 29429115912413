import apiClient from "./baseService";

const defaultAuditFileExtension = "ucx"; 

export default {
	getCustomerList(defaultNode, vendor) {
		return apiClient.get(`/returnlist?q=2022&nodes=${defaultNode}&vendor=${vendor}`);
	},
	getAllSites(nodes) { // Actually restricted to a node ID
		return apiClient.get(`/amgetsitetree?nodes=${nodes}`)
	},
	getScanDates(nodes) {
		return apiClient.get(`/amgetscandates?nodeId=${nodes}`);
	},
	getAllScanDates(siteId) {
		return apiClient.get(`/amgetallscandates?amSiteId=${siteId}`);
	},
	deleteSiteAndAudits(siteId) {
		return apiClient.post(`/deleteAllAudits?siteId=${siteId}&andSite=1&xyz=1`)
	},
	deleteAudit(siteId, scanDate) {
		return apiClient.post(`deleteAllAudits?xyz=1&siteId=${siteId}&scanDate=${scanDate}`)
	},
	detectVendorBackupType(filename) {
		return apiClient.get(`/detectVendorBackupType?filename=${encodeURIComponent(filename)}`);
	},
    addVoiceAuditSite(siteName, nodeId, vendor, clientId, sowId, phaseId, partnerType, ip, port, username, password, siteType, sap1, sap2) {
		return apiClient.get(`/amaddsite?wizSiteId=-1&wizSiteName=${siteName}&wizNodeId=${nodeId}&wizVendor=${vendor}&clientId=${clientId}&sowId=${sowId}&phaseId=${phaseId}&partnerType=${partnerType}&wizPABXIP1=${ip}&wizPABXPort1=${port}&wizPABXUser=${username}&wizPABXPass=${encodeURIComponent(password)}&wizConType=${siteType}&sap1=${sap1}&sap2=${sap2}`);
	},
    addVoiceAuditSchedule(siteId, selectedType, decoderType, fileName, version, outputFormat, hldTemplate, directMode, emailAddress, emailTemplate, cmdParamerters) {
		let parameters = `live ${decoderType} -s ${siteId}`;
		if (fileName.split(".").pop() === defaultAuditFileExtension) {
			parameters = `upload -s ${siteId}`
		}

		if (cmdParamerters && cmdParamerters.length > 0) {
			parameters += cmdParamerters.join('');
		}

		let url =
        `/amaddschedule?amSiteId=${siteId}&nextDateTime=-1&desc=FileAudit&type=voiceAudit&exe=${selectedType}&cmd=${parameters}&sourceFile=${encodeURIComponent(fileName)}&LLD=1&SPA=${version}&outputFormat=${outputFormat || "Excel"}&HLD=${hldTemplate || "_Default_Template.docx"}&maxRetries=1`;
		if (!directMode) {
			url += `&email=${emailAddress}&emailTemplate=${emailTemplate || ""}`;
		}

		return apiClient.get(url);
	},
	exportAudit(siteId, options) {
		return apiClient.get(`/exportaudit?amSiteId=${siteId}&options=${options}`, {
			responseType: 'arraybuffer',
			timeout: 600000 // 10 minutes in milliseconds
		});
	},
	exportSampleAudit(options) {
		return apiClient.get(`/exportaudit?options=${options}&sampleExport=1`, {
			responseType: 'arraybuffer',
			timeout: 600000 // 10 minutes in milliseconds
		});
	},
	mergeDoc(hld, siteId, scanDate, version, hldFallback) {
		return apiClient.get(`/ammergedoc?template=${hld}&amSiteId=${siteId}&amScanDate=${scanDate}&spa=${version}&template2=${hldFallback}`, {
			responseType: 'arraybuffer',
			timeout: 600000 // 10 minutes in milliseconds
		});
	},
	getPABXTypes() {
		return apiClient.get("/pabxVendors?backupsOnly=true");
	},
	getLLDList() {
		return apiClient.get("/lldList");
	},
	updateVoiceAuditSite(existingSite, ip, port, username, password) {
		return apiClient.get(
			`/amaddsite?wizUpdateMode=1&wizSiteId=${existingSite.siteId}&wizSiteName=${existingSite.siteName}&wizNodeId=${existingSite.nodeId}&wizPABXIP1=${ip}&wizPABXPort1=${port}&wizPABXUser=${username}&wizPABXPass=${encodeURIComponent(password)}`);
	}
}