const formatFileName = (format, item) => {
    try {
        if(!format || !item) {
            return false;
        }

        // Turn the string into an array of chars
        const keys = Object.keys(item);

        // Create base file name using format string, then replace it where needed
        let fileName = format;
        
        // Loops over every character in the string
        keys.forEach((key) => {
            const regex = new RegExp(`%${key}%`, 'g');
            
            fileName = fileName.replace(regex, item[key]);
        });
        
        // Return the final built file name
        return fileName;
    }
    catch(error) {
        console.log('error', error)
    }
}

export { formatFileName };