import Vue from "vue";
import jwtDecode from "jwt-decode";
import { refreshNewToken } from "@/utils/authenticationHelper"
import authService from "@/api/authService";

const state = {
  accessToken: localStorage.getItem("accessToken") || "",
  refreshToken: localStorage.getItem("refreshToken") || "",
  acceptedTerms: localStorage.getItem("acceptedTerms") || false,
  sso: {},
  overriddenProfiles: []
};

let refreshTimer = null;

const mutations = {
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
    localStorage.setItem("accessToken", accessToken);
    // Enable auto refresh in non SSO mode
    clearTimeout(refreshTimer);
    
    var currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + 20); // Add time so we refresh a few minutes sooner.

    refreshTimer = setTimeout(() => {
      var ssoSession = Vue.$cookies.get('SSO_Session');
      if (!ssoSession || !ssoSession.SSO) {
        refreshNewToken();
      } else {
        authService.validateSsoSession(ssoSession).then(res => {
          mutations.setAccessToken(res.data);
        }).catch(e => {
          if (e.response && e.response.data.location) {
            window.location.href = e.response.data.location;
          }
        });
      }
    }, (getters.getTokenExpirationDate(state).getTime() - currentTime.getTime()))
  },
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
    localStorage.setItem("refreshToken", refreshToken);
  },
  removeAccessToken(state) {
    clearInterval(refreshTimer);
    state.accessToken = "";
  },
  removeRefreshToken(state) {
    state.refreshToken = "";
  },
  setAcceptTerms(state, accepted) {
    state.acceptedTerms = accepted;
  },
  setSSO(state, sso) {
    state.sso = sso;

    // This is here to keep backwards compatibility for Mitel
    if (state.sso && state.sso.Type && state.sso.Type.toUpperCase() === 'MITEL' && !state.sso.WorkflowLabel) {
      state.sso.WorkflowLabel = 'Workflow order number';
    }
  },
  setOverriddenProfiles(state, profiles) {
    state.overriddenProfiles = profiles;
  }
};

const actions = {
  login({ commit }, { accessToken, refreshToken }) {
    commit("setAccessToken", accessToken);

    if (refreshToken) {
      commit("setRefreshToken", refreshToken);
    }
  },
  acceptTerms({ commit }) {
    localStorage.setItem("acceptedTerms", true);
    commit("setAcceptTerms", true);
  },
  clearAcceptTerms({ commit }) {
    localStorage.removeItem("acceptedTerms");
    commit("setAcceptTerms", false);
  },
  setSSO({ commit }, sso) {
    commit("setSSO", sso)
  },
  logout({ commit }) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("acceptedTerms");

    commit("removeAccessToken");
    commit("setAcceptTerms", false);
  },
  overideProfiles({ commit }, profiles) {
    commit("setOverriddenProfiles", profiles);
  },
  async initialize({ commit, dispatch }) {
    var token = localStorage.getItem("accessToken");

    if (token) {
      const t = jwtDecode(token);

      // If the token has expired, clear the existing accepted terms state
      const validExp = new Date(0).setUTCSeconds(t.exp) > new Date();
      if (!validExp) {
        dispatch("clearAcceptTerms")
      }

      commit("setAccessToken", token);
    }

    // If no token is present also clear the accepted terms state
    if (!token) {
      dispatch("clearAcceptTerms");
    }

    // Set terms agreement
    const acceptedTerms = localStorage.getItem("acceptedTerms")
    if (token && acceptedTerms) {
      commit("setAcceptTerms", acceptedTerms);
    }
  }
};

const getters = {
  getToken: state => state.accessToken,
  getRefreshToken: state => state.refreshToken,
  isLoggedIn: (state, getters) => {
    if (!state.accessToken) {
      return false;
    }
    return getters.getTokenExpirationDate > new Date();
  },
  getSSO: state => state.sso,
  getAcceptedTerms: state => state.acceptedTerms,
  getUsername: state => {
    if(!state.accessToken) {
      return "UCentric ZT"
    }

    const token = jwtDecode(state.accessToken);
    return token.username;
  },
  getTokenExpirationDate: (state) => {
    if (!state.accessToken) {
      return null;
    }

    const token = jwtDecode(state.accessToken);
    if (!token.exp) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);
    return date;
  },
  getGroupProfile: state => {
    if (!state.accessToken) {
      return null;
    }

    const token = jwtDecode(state.accessToken);
    return token.profile;
  },
  getDefaultNode: state => {
    if (!state.accessToken) {
      return 0;
    }

    const token = jwtDecode(state.accessToken);
    const nodes = token.profile.nodes || [];

    var node ='\\';

    // Assign default node id.
    if (nodes.length > 0) {
      node += token.profile.nodes[0] + '\\';
    } 

    // If user is not an SPA admin then we also want to restrict to the users logged in username.
    if (!getters.isSPAAdmin(state) && (!getters.getSSO(state) || !getters.getSSO(state).Type)) {
      node += getters.getUsername(state);
    }
    return node.length > 1 ? node.replace(/\\+$/, '') : node;
  },
  getAllowedProvisioningTypes: state => {
    if (!state.accessToken) {
      return null;
    }

    const token = jwtDecode(state.accessToken);
    return token.profile.SPADests;
  },
  getHandsets: state => {
    if (!state.accessToken) {
      return null;
    }

    const token = jwtDecode(state.accessToken);
    return token.profile.SPAHandsets
  },
  getOverriddenProfiles() {
    return state.overriddenProfiles;
  },
  isDirectMode: state => {
    if (!state.accessToken) {
      return null
    }

    const token = jwtDecode(state.accessToken);
    return token.profile.SPADelivery === "direct";
  },
  isMitel: state => {
    if (!state.accessToken) {
      return false;
    }

    const token = jwtDecode(state.accessToken);
    return token.Mitel === "1"
  },
  isSPAAdmin: state => {
    if (!state.accessToken) {
      return false;
    }

    const token = jwtDecode(state.accessToken);
    if (token.profile.pnlSPAAdmin) {
      return token.profile.pnlSPAAdmin.vis === 1;
    } else {
      return false;
    }
  },
  isAuditAdmin: state => {
    if (!state.accessToken) {
      return false;
    }

    const token = jwtDecode(state.accessToken);
    return token.profile.pnlAdmin && token.profile.pnlAdmin.vis === 1;
  },
  provisioningDisabled: state => {
    if (!state.accessToken) {
      return true;
    }

    const token = jwtDecode(state.accessToken);
    return token.profile.SPAHidePro === 1;
  },

  auditingDisabled: state => {
    if (!state.accessToken) {
      return true;
    }

    const token = jwtDecode(state.accessToken);
    return token.profile.SPAHideAud === 1;
  },

  // SPASources
  getAllowedPABXTypes: state => {
    if (!state.accessToken) {
      return [];
    }

    const token = jwtDecode(state.accessToken);
    return token.profile.SPASources ? token.profile.SPASources.split(",") : [];
  },

  getSAPReferences: state => {
    if (!state.accessToken) {
      return {};
    }
    const token = jwtDecode(state.accessToken);
    return token.SAP || {};
  }
};

export default {
    state,
    mutations,
    actions,
    getters
};
