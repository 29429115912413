import provisioningService from "@/api/provisioningService";
import auditService from "@/api/auditService";

const state = {
	provisioningTypes: {},
	pabxTypes: [],
	lldTypes: []
}

const mutations = {
	setProvisioningTypes(state, types) {
		state.provisioningTypes = types;
	},
	setPabxTypes(state, types) {
		state.pabxTypes = types;
	},
	setLLDTypes(state, types) {
		state.lldTypes = types;
	}
}

const actions = {
	async initializeProvisioningTypes({ commit }) {
    	// Fetch and set vendors
    	const types = await provisioningService.getProvisioningTypes();
    	commit("setProvisioningTypes", types.data);
  	},
	async initializePABXTypes({ commit }) {
		const pabxTypes = await auditService.getPABXTypes()
		const types = [];
		
		// PABXTypes need to be formatted in a way so that they are compatible with the SPA
		pabxTypes.data.forEach(vendor => {
			const pabx = {
				type: vendor.uniexe,
				databaseType: vendor.dbid,
				desc: vendor.name,
			  	visible: false,
			  	icon: vendor.spaIcon,
				supportedFileTypes: vendor.supportedFileTypes || [],
				extraSwitches: vendor.extraSwitches || [],
				fields: vendor.fields,
				settings: vendor.settings
			}

			types.push(pabx);
		})

		await commit("setPabxTypes", types);

		return true;
	},
	async initializeLLDTypes({ commit }) {
		try {
			const types = await auditService.getLLDList();
			commit("setLLDTypes", types.data);

			return true;
		}
		catch(error) {
			return false;
		}
	}
}

const getters = {
	getProvisioningTypes: state => state.provisioningTypes,
	getPABXTypes: state => {
		if (state.pabxTypes) {
			// Order the pbx's by name
			return state.pabxTypes.sort(function (a, b) {
				if (a.desc < b.desc) {
				  return -1;
				}

				if (a.desc > b.desc) {
				  return 1;
				}

				return 0
			});
		}
		return [];
	},
	getLLDTypes: state => state.lldTypes
}

export default {
	state,
	mutations,
	actions,
	getters
}